import { BaseService } from "../BaseService";

class BackofficeService extends BaseService {
  basePath = "/api/v1/backoffice";
  async getOrders(page: number, perPage: number, filters: any) {
    try {
      let route = `/pedido` + `?page=${page}&perPage=${perPage}`;

      if (filters) {
        Object.keys(filters).forEach((key) => {
          if (filters[key] && filters[key].length > 0) {
            if (Array.isArray(filters[key])) {
              route += `&${key}=${encodeURIComponent(JSON.stringify(filters[key]))}`;
            } else {
              route += `&${key}=${filters[key]}`;
            }
          }
        });
      }

      const response = await this.instance.get(this.basePath + route, this.getConfigHeaders());
      return response.data.results;
    } catch (error: any) {
      return this.handleResponseError(error);
    }
  }

  async getInformationOrder(pedido_id: number) {
    try {
      const route = `/informacao-pedido` + `?pedidoId=${pedido_id}`;
      const response = await this.instance.get(this.basePath + route, this.getConfigHeaders());
      return response.data.results;
    } catch (error: any) {
      return this.handleResponseError(error);
    }
  }

  async saveInformationOrder(pedido_id: number, information: any) {
    try {
      const route = `/informacao-pedido`;
      const response = await this.instance.post(this.basePath + route, { pedidoId: pedido_id, informacao: information }, this.getConfigHeaders());
      return response.data.results;
    } catch (error: any) {
      return this.handleResponseError(error);
    }
  }

  async getStatus(filters: any, status = ["Agendar", "Agendado", "Em transporte", "Cancelado", "Veículo avariado"], statusModality = ["Agendar"]) {
    try {
      let route = `/pedido/status`;

      route += `?statusModality=${encodeURIComponent(JSON.stringify(statusModality))}`;

      if (filters) {
        if (Object.keys(filters).includes("status")) {
          if (filters.status.length == 0) {
            route += `&status=${encodeURIComponent(JSON.stringify(status))}`;
          }
        } else {
          route += `&status=${encodeURIComponent(JSON.stringify(status))}`;
        }

        Object.keys(filters).forEach((key) => {
          if (filters[key] && filters[key].length > 0) {
            if (Array.isArray(filters[key])) {
              route += `&${key}=${encodeURIComponent(JSON.stringify(filters[key]))}`;
            } else {
              route += `&${key}=${filters[key]}`;
            }
          }
        });
      }

      const response = await this.instance.get(this.basePath + route, this.getConfigHeaders());
      return response.data.results;
    } catch (error: any) {
      return this.handleResponseError(error);
    }
  }

  async exportOrders(fileType = "xlsx", filters: any) {
    try {
      let route = `/pedido/export` + `?fileType=${fileType}`;

      if (filters) {
        Object.keys(filters).forEach((key) => {
          if (filters[key] && filters[key].length > 0) {
            if (Array.isArray(filters[key])) {
              route += `&${key}=${encodeURIComponent(JSON.stringify(filters[key]))}`;
            } else {
              route += `&${key}=${filters[key]}`;
            }
          }
        });
      }

      const response = await this.instance.get(this.basePath + route, this.getConfigHeaders());
      return response.data.results;
    } catch (error: any) {
      return this.handleResponseError(error);
    }
  }

  handleResponseError(err: any) {
    if (err.response?.status === 401) {
      if (err.response?.data?.error?.message === "Invalid token") {
        localStorage.removeItem("BEARER_TOKEN");
        window.location.reload();
        return;
      }
    }

    if (err.response?.status === 422) {
      return Promise.reject(err.response.data.error.errors);
    }

    if (err.response?.status === 500) {
      console.log(err);
      if (err.response.data.error.message) {
        return Promise.reject(err.response.data.error.message);
      } else if (err.response.data.error.error.message) {
        return Promise.reject(err.response.data.error.error.message);
      } else {
        return Promise.reject(new Error("Erro interno do servidor"));
      }
    }

    if (err.response?.status === 503) {
      return Promise.reject(new Error("Falha na comunicação com o servidor, favor tentar novamente mais tarde"));
    }
  }
}

export default new BackofficeService("", "");
