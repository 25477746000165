import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "d-flex justify-space-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_file_input = _resolveComponent("v-file-input")!
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, {
    onSubmit: _withModifiers(_ctx.importCsv, ["prevent"])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[4] || (_cache[4] = _createElementVNode("h1", null, "Importar CSV", -1)),
        _createElementVNode("div", null, [
          _createVNode(_component_v_btn, {
            class: "text-white mr-2",
            color: "grey",
            onClick: _ctx.backToPreviousPage
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Voltar")
            ])),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_v_btn, {
            class: "text-white",
            color: "movida-orange",
            type: "submit",
            disabled: _ctx.btn_disabled
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Confirmar")
            ])),
            _: 1
          }, 8, ["disabled"])
        ])
      ]),
      _createVNode(_component_v_file_input, {
        modelValue: _ctx.csvFile,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.csvFile) = $event)),
        accept: ".csv",
        label: "Escolha o arquivo CSV",
        outlined: "",
        dense: "",
        required: ""
      }, null, 8, ["modelValue"]),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_v_progress_linear, {
            key: 0,
            indeterminate: ""
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_v_snackbar, {
        modelValue: _ctx.snackbar,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.snackbar) = $event)),
        timeout: _ctx.timeout
      }, {
        actions: _withCtx(() => [
          _createVNode(_component_v_btn, {
            variant: "text",
            onClick: _ctx.closeSnack
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode("Fechar")
            ])),
            _: 1
          }, 8, ["onClick"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_v_icon, { class: "ml-1" }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("mdi-information-outline")
            ])),
            _: 1
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.snackbarText) + " ", 1)
        ]),
        _: 1
      }, 8, ["modelValue", "timeout"]),
      (_ctx.results.length > 0)
        ? (_openBlock(), _createBlock(_component_v_data_table, {
            key: 1,
            items: _ctx.results,
            headers: _ctx.headers,
            class: "mt-4"
          }, {
            headers: _withCtx(() => [
              _createElementVNode("tr", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header) => {
                  return (_openBlock(), _createElementBlock("th", {
                    key: header.value,
                    class: "text-left"
                  }, _toDisplayString(header.text), 1))
                }), 128))
              ])
            ]),
            item: _withCtx(({ item }) => [
              _createElementVNode("tr", null, [
                _createElementVNode("td", null, _toDisplayString(item.value.pedido_frota_id), 1),
                _createElementVNode("td", null, _toDisplayString(item.value.agendamento_id), 1),
                _createElementVNode("td", null, _toDisplayString(item.value.filial), 1),
                _createElementVNode("td", null, _toDisplayString(item.value.message), 1)
              ])
            ]),
            _: 1
          }, 8, ["items", "headers"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onSubmit"]))
}