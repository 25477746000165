import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/brand-light.svg'


const _hoisted_1 = { class: "d-flex justify-center align-center min-h-100vh" }
const _hoisted_2 = { class: "mt-2 d-flex flex-column w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_flex = _resolveComponent("v-flex")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "logo" }, [
      _createElementVNode("img", {
        alt: "Logo Movida",
        src: _imports_0
      })
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_flex, { class: "justify-center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, {
                elevation: "5",
                class: "pa-md-16 pa-sm-4 px-md-16 px-sm-4 rounded-lg"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, { "no-gutters": "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_container, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, {
                            onClick: _ctx.backPage,
                            class: "mb-5"
                          }, {
                            default: _withCtx(() => _cache[0] || (_cache[0] = [
                              _createTextVNode(" mdi-arrow-left")
                            ])),
                            _: 1
                          }, 8, ["onClick"]),
                          _cache[4] || (_cache[4] = _createElementVNode("h1", { class: "text-left text-neutral-900" }, "Selecionar ambiente", -1)),
                          _cache[5] || (_cache[5] = _createElementVNode("p", { class: "text-neutral-600" }, [
                            _createTextVNode(" Você possui acesso aos ambientes abaixo."),
                            _createElementVNode("br"),
                            _createTextVNode(" Selecione em qual você deseja continuar a operação:")
                          ], -1)),
                          _createElementVNode("div", _hoisted_2, [
                            _createVNode(_component_v_select, {
                              color: 'movida-orange',
                              items: _ctx.modules,
                              "item-title": "name",
                              "item-value": "id",
                              placeholder: "Selecione um item",
                              dense: "",
                              variant: "outlined",
                              "hide-details": "",
                              "onUpdate:modelValue": _ctx.changeModuleSelected
                            }, null, 8, ["items", "onUpdate:modelValue"]),
                            _createVNode(_component_v_checkbox, { label: "Lembrar ambiente como padrão" }),
                            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "border border-slate-300/900 my-5" }, null, -1)),
                            _createVNode(_component_v_btn, {
                              color: "movida-orange",
                              size: "large",
                              block: "",
                              onClick: _ctx.goToHome
                            }, {
                              default: _withCtx(() => [
                                _cache[2] || (_cache[2] = _createTextVNode(" Continuar ")),
                                _createVNode(_component_v_icon, { class: "ml-1" }, {
                                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                                    _createTextVNode(" mdi-arrow-right")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["onClick"])
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ], 64))
}