<template>
  <header>
    <div class="d-flex justify-space-between">
      <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
      <v-col cols="5" md="5" class="nav-header d-flex justify-end">
        <v-menu bottom offset-y offset-x>
          <template v-slot:activator="{ props }">
            <v-btn prepend-icon="mdi-account-circle-outline" variant="text" v-bind="props"> {{ user.nome }} </v-btn>
          </template>
          <v-list>
            <v-subheader class="d-flex py-3 px-4 mr-2 align-center">
              <v-icon class="mr-4 neutral-500">mdi-account-circle-outline</v-icon>
              <div class="d-flex flex-column">
                <span class="neutral-700">{{ user.nome }}</span>
                <span class="text-caption neutral-500 font-weight-light">{{ user.email }}</span>
              </div>
            </v-subheader>
            <v-divider></v-divider>
            <v-list-item v-for="(item, index) in rightMenu" :key="index" :value="index" :to="item.to">
              <template v-slot:prepend>
                <v-icon :icon="item.icon"></v-icon>
              </template>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </div>
    <v-divider class="mx-5"></v-divider>
  </header>
</template>

<script>
export default {
  //TODO<Jean Paul>: Falta migrar este component para classe com typescript
  name: "NavHeader",
  props: {
    routes: {
      type: Array,
      required: true,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    rightMenu: {
      type: Array,
      required: true,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    user: {
      type: Object,
      required: true,
      default: function () {
        return {
          name: "não encontrado",
          email: "não encontrado",
        };
      },
    },
  },
  computed: {
    breadcrumbs() {
      if (!this.isFilledModules()) {
        return this.initialBreadcrumb();
      }

      const currentRoute = this.$router.currentRoute.value.path;

      if (currentRoute === "/home") {
        return this.initialBreadcrumb();
      }

      const routes = currentRoute
        .split("/")
        .filter((item) => !!item)
        .reduce(
          (accumulator, current) => {
            const routeString = `${accumulator.lastRoute}/${current}`;
            const route = this.routes.find((element) => element.href === routeString);
            accumulator.routes.push(route);
            accumulator.lastRoute = routeString;
            return accumulator;
          },
          { routes: [], lastRoute: "" }
        ).routes;

      const breadcrumbs = this.initialBreadcrumb();
      const lastRoute = routes.map((item) => {
        return {
          text: item?.text,
          href: item?.href,
          disabled: currentRoute === item?.href,
        };
      });

      breadcrumbs.push(...lastRoute);

      return breadcrumbs;
    },
  },
  methods: {
    isFilledModules() {
      return this.$props.routes.length > 0;
    },
    initialBreadcrumb() {
      return [
        {
          text: "Início",
          href: "/home",
          disabled: false,
        },
      ];
    },
  },
};
</script>
<style lang="css">
.v-list-item__prepend > .v-icon {
  margin-inline-end: 14px !important;
}
</style>
