import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-h4" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_v_pagination = _resolveComponent("v-pagination")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_form, {
      "validate-on": "submit",
      onSubmit: _withModifiers(_ctx.save, ["prevent"])
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => [
                _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.pageTitle), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, { align: "end" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  class: "mr-4",
                  variant: "text",
                  href: "/leadtime#deadlines"
                }, {
                  default: _withCtx(() => _cache[12] || (_cache[12] = [
                    _createTextVNode(" Voltar")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_btn, {
                  class: "bg-red rounded-lg float-right",
                  type: "submit",
                  loading: _ctx.loading
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(this.id > 0 ? "Atualizar" : "Criar"), 1)
                  ]),
                  _: 1
                }, 8, ["loading"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              md: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_select, {
                  modelValue: _ctx.brand,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.brand) = $event)),
                  loading: _ctx.loadingBrands,
                  items: _ctx.brands,
                  "item-title": "Marca",
                  "item-value": "MarcaID",
                  label: "Selecione a marca",
                  "return-object": "",
                  "single-line": "",
                  disabled: !!_ctx.brandId
                }, null, 8, ["modelValue", "loading", "items", "disabled"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              md: "2"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.billingDeadline,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.billingDeadline) = $event)),
                  modelModifiers: { number: true },
                  label: "Faturamento",
                  type: "number",
                  required: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              md: "2"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.implementationDeadline,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.implementationDeadline) = $event)),
                  modelModifiers: { number: true },
                  label: "Implantação",
                  type: "number",
                  required: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              md: "2"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.operationalDeadline,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.operationalDeadline) = $event)),
                  modelModifiers: { number: true },
                  label: "Operacional",
                  type: "number",
                  required: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["onSubmit"]),
    _createVNode(_component_v_divider, { class: "my-4" }),
    _createVNode(_component_v_dialog, {
      transition: "dialog-top-transition",
      width: "auto"
    }, {
      activator: _withCtx(({ props: activatorProps }) => [
        _createVNode(_component_v_btn, _mergeProps(activatorProps, { variant: "text" }), {
          default: _withCtx(() => _cache[13] || (_cache[13] = [
            _createTextVNode(" Visualizar logs ")
          ])),
          _: 2
        }, 1040)
      ]),
      default: _withCtx(({ isActive }) => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_toolbar, { title: "Logs de alteração" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_actions, { class: "justify-end" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      icon: "",
                      onClick: ($event: any) => (isActive.value = false)
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => _cache[14] || (_cache[14] = [
                            _createTextVNode("mdi-close")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_v_data_table_server, {
              "items-per-page": _ctx.itemsPerPage,
              "onUpdate:itemsPerPage": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.itemsPerPage) = $event)),
              headers: _ctx.headers,
              items: _ctx.items,
              loading: _ctx.loadingLogs,
              "item-value": "name",
              class: "elevation-1",
              "hide-default-footer": ""
            }, {
              loading: _withCtx(() => [
                _createVNode(_component_v_sheet, { class: "d-flex align-center justify-center pt-3 pb-3" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_chip, null, {
                      default: _withCtx(() => _cache[15] || (_cache[15] = [
                        _createTextVNode(" Carregando... ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              bottom: _withCtx(() => _cache[16] || (_cache[16] = [])),
              [`item.created_at`]: _withCtx(({ item }) => [
                _createTextVNode(_toDisplayString(_ctx.format_date(item.value.created_at)), 1)
              ]),
              [`item.changes`]: _withCtx(({ item }) => [
                (item.value.changes && item.value.changes.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.value.changes, (change, index) => {
                        return (_openBlock(), _createElementBlock("div", { key: index }, [
                          _createTextVNode(" Campo: " + _toDisplayString(change.field) + "; ", 1),
                          (change.old)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                                _cache[17] || (_cache[17] = _createTextVNode(" Valor antigo: ")),
                                (_ctx.isDate(change.old))
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.format_date(change.old)), 1))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(change.old), 1)),
                                _cache[18] || (_cache[18] = _createTextVNode("; "))
                              ]))
                            : _createCommentVNode("", true),
                          _cache[19] || (_cache[19] = _createTextVNode(" Valor novo: ")),
                          (_ctx.isDate(change.new))
                            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.format_date(change.new)), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(change.new), 1))
                        ]))
                      }), 128))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_8, "Sem registros."))
              ]),
              _: 2
            }, 1032, ["items-per-page", "headers", "items", "loading"]),
            _createVNode(_component_v_row, {
              class: "mt-2 d-flex align-center",
              justify: "center"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_v_pagination, {
                    modelValue: _ctx.currentPage,
                    "onUpdate:modelValue": [
                      _cache[5] || (_cache[5] = ($event: any) => ((_ctx.currentPage) = $event)),
                      _ctx.loadPage
                    ],
                    length: _ctx.numberOfPages,
                    "total-visible": 7,
                    color: "orange",
                    onNext: _ctx.nextPage,
                    onPrev: _ctx.prevPage
                  }, null, 8, ["modelValue", "length", "onNext", "onPrev", "onUpdate:modelValue"]),
                  _createElementVNode("div", null, _toDisplayString(_ctx.pageFrom) + " a " + _toDisplayString(_ctx.pageTo) + " de " + _toDisplayString(_ctx.totalOfRows) + " resultados", 1)
                ])
              ]),
              _: 1
            })
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }),
    _createVNode(_component_v_snackbar, {
      modelValue: _ctx.snackbar.show,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.snackbar.show) = $event)),
      timeout: _ctx.snackbar.timeout,
      color: _ctx.snackbar.success ? 'success' : 'error'
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_v_btn, {
          variant: "text",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (this.snackbar.show = false))
        }, {
          default: _withCtx(() => _cache[20] || (_cache[20] = [
            _createTextVNode(" Fechar ")
          ])),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_icon, { class: "mr-1" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.snackbar.success ? "mdi-check-circle-outline" : "mdi-close-octagon"), 1)
          ]),
          _: 1
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.snackbar.text) + " ", 1)
      ]),
      _: 1
    }, 8, ["modelValue", "timeout", "color"]),
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.confirmDialog,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.confirmDialog) = $event)),
          "max-width": "500px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "headline mt-2" }, {
                  default: _withCtx(() => _cache[21] || (_cache[21] = [
                    _createTextVNode("Confirme a atualização")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => _cache[22] || (_cache[22] = [
                    _createTextVNode("Você tem certeza que deseja atualizar este item?")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_divider, { class: "mt-2 pb-4" }),
                _createVNode(_component_v_card_actions, { class: "pb-4" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      text: "",
                      onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.confirmDialog = false))
                    }, {
                      default: _withCtx(() => _cache[23] || (_cache[23] = [
                        _createTextVNode("Cancelar")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      class: "bg-red rounded-lg",
                      onClick: _ctx.confirmUpdate
                    }, {
                      default: _withCtx(() => _cache[24] || (_cache[24] = [
                        _createTextVNode("Atualizar")
                      ])),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_v_snackbar, {
          modelValue: _ctx.snackbar.show,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.snackbar.show) = $event)),
          timeout: 3000,
          color: _ctx.snackbar.success ? 'success' : 'error'
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.snackbar.text) + " ", 1),
            _createVNode(_component_v_btn, {
              color: "white",
              text: "",
              onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.snackbar.show = false))
            }, {
              default: _withCtx(() => _cache[25] || (_cache[25] = [
                _createTextVNode("Fechar")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue", "color"])
      ]),
      _: 1
    })
  ], 64))
}