import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-space-between" }
const _hoisted_2 = { class: "text-decoration-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[12] || (_cache[12] = _createElementVNode("h1", { class: "" }, "Atualizar Cluster", -1)),
      _createElementVNode("a", _hoisted_2, [
        _createVNode(_component_v_btn, {
          class: "btn-action text-white elevation-0",
          color: "grey",
          href: "/clusters"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, null, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode(" mdi-cancel ")
              ])),
              _: 1
            }),
            _cache[9] || (_cache[9] = _createTextVNode(" Voltar "))
          ]),
          _: 1
        }),
        _createVNode(_component_v_btn, {
          class: "text-white elevation-0",
          color: "movida-orange",
          onClick: _ctx.update,
          disabled: _ctx.btn_disabled
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, null, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createTextVNode(" mdi-plus ")
              ])),
              _: 1
            }),
            _cache[11] || (_cache[11] = _createTextVNode(" Salvar "))
          ]),
          _: 1
        }, 8, ["onClick", "disabled"])
      ])
    ]),
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_v_sheet, {
              key: 0,
              class: "d-flex align-center justify-center pt-3 pb-3"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_chip, {
                  color: _ctx.serviceErro ? 'red' : ''
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.text), 1)
                  ]),
                  _: 1
                }, 8, ["color"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              md: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.clusterStoreRequest.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.clusterStoreRequest.name) = $event)),
                  label: "Nome",
                  rules: _ctx.rules,
                  "hide-details": "auto",
                  disabled: _ctx.loading
                }, null, 8, ["modelValue", "rules", "disabled"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              md: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.clusterStoreRequest.slug,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.clusterStoreRequest.slug) = $event)),
                  label: "Slug",
                  rules: _ctx.rules,
                  "hide-details": "auto",
                  disabled: _ctx.loading
                }, null, 8, ["modelValue", "rules", "disabled"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              md: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.clusterStoreRequest.description,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.clusterStoreRequest.description) = $event)),
                  label: "Descrição",
                  rules: _ctx.rules,
                  "hide-details": "auto",
                  disabled: _ctx.loading
                }, null, 8, ["modelValue", "rules", "disabled"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              md: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_select, {
                  modelValue: _ctx.clusterStoreRequest.sales_channel_id,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.clusterStoreRequest.sales_channel_id) = $event)),
                  readonly: true,
                  disabled: false,
                  items: _ctx.channels,
                  label: "Canais de Venda",
                  hint: "Escolha o canal de venda",
                  "persistent-hint": "",
                  "item-title": "name",
                  "item-value": "id"
                }, null, 8, ["modelValue", "items"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              md: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.clusterStoreRequest.min,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.clusterStoreRequest.min) = $event)),
                  label: "Min",
                  "hide-details": "auto",
                  disabled: _ctx.loading
                }, null, 8, ["modelValue", "disabled"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              md: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.clusterStoreRequest.max,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.clusterStoreRequest.max) = $event)),
                  label: "Max",
                  "hide-details": "auto",
                  disabled: _ctx.loading
                }, null, 8, ["modelValue", "disabled"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              md: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_switch, {
                  modelValue: _ctx.clusterStoreRequest.is_enabled,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.clusterStoreRequest.is_enabled) = $event)),
                  color: "movida-orange",
                  label: _ctx.clusterStoreRequest.is_enabled ? 'Ativado' : 'Desativado',
                  "item-title": "Ativo?",
                  "item-value": "is_enabled",
                  disabled: _ctx.loading
                }, null, 8, ["modelValue", "label", "disabled"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_snackbar, {
      modelValue: _ctx.snackbar,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.snackbar) = $event)),
      timeout: _ctx.timeout
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_v_btn, {
          variant: "text",
          onClick: _ctx.closeSnack
        }, {
          default: _withCtx(() => _cache[14] || (_cache[14] = [
            _createTextVNode(" Fechar ")
          ])),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_icon, { class: "ml-1" }, {
          default: _withCtx(() => _cache[13] || (_cache[13] = [
            _createTextVNode(" mdi-information-outline ")
          ])),
          _: 1
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.text) + " ", 1)
      ]),
      _: 1
    }, 8, ["modelValue", "timeout"])
  ], 64))
}