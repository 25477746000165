<template>
  <div class="d-flex justify-space-between">
    <h1 class="">Criar Cluster</h1>
    <a class="text-decoration-none">
      <v-btn class="btn-action text-white elevation-0" color="grey" href="/clusters">
        <v-icon> mdi-cancel</v-icon>
        Voltar
      </v-btn>
      <v-btn class="text-white elevation-0" color="movida-orange" @click="store" v-bind:disabled="btn_disabled">
        <v-icon> mdi-plus</v-icon>
        Salvar
      </v-btn>
    </a>
  </div>
  <v-divider></v-divider>
  <v-container>
    <template v-if="loading">
      <v-sheet class="d-flex align-center justify-center pt-3 pb-3">
        <v-chip :color="serviceErro ? 'red' : ''"> {{ text }}</v-chip>
      </v-sheet>
    </template>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field v-model="clusterStoreRequest.name" label="Nome" :rules="rules" hide-details="auto"></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field v-model="clusterStoreRequest.slug" label="Slug" :rules="rules" hide-details="auto"></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field v-model="clusterStoreRequest.description" label="Descrição" :rules="rules" hide-details="auto"></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-select v-model="clusterStoreRequest.sales_channel_id" :readonly="true" :items="channels" label="Canais de Venda" hint="Escolha o canal de venda" persistent-hint item-title="name" item-value="id"></v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field v-model="clusterStoreRequest.min" label="Min" :rules="rulesMinMax" hide-details="auto"></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field v-model="clusterStoreRequest.max" label="Max" :rules="rulesMinMax" hide-details="auto"></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-switch v-model="clusterStoreRequest.is_enabled" color="movida-orange" :label="clusterStoreRequest.is_enabled ? 'Ativado' : 'Desativado'" item-title="Ativo?" item-value="is_enabled"></v-switch>
      </v-col>
    </v-row>
  </v-container>
  <v-snackbar v-model="snackbar" :timeout="timeout">
    <v-icon class="ml-1"> mdi-information-outline</v-icon>
    {{ text }}
    <template v-slot:actions>
      <v-btn variant="text" @click="closeSnack"> Fechar</v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import * as services from "@/services";

import messages from "@/constants/messages";
import { ClusterStoreRequest } from "@/models/cluster/ClusterStoreRequest";
import { toRaw } from "vue";
import { getFromStorage } from "@/utils/localStorage";

@Options({
  components: {},
})
export default class ClusterCreateView extends Vue {
  public rules = [(value: any) => !!value || "Required.", (value: string) => (value && value.length >= 3) || "Min 3 characters"];
  public rulesMinMax = [(value: any) => !!value || "Required.", (value: string) => (value && value.length >= 1) || "Min 1 character"];

  public messages = {};
  public module_channels: any;
  public serviceErro = false;
  public sortSelected = "name";
  public loading = false;
  public needleSearch = null;
  public snackbar = false;
  public text = "Carregando os dados...";
  public timeout = 10000;
  public btn_disabled = false;
  public channels = [];

  public name = "";
  public slug = "";
  public description = "";
  public channelId = 26;

  public clusterStoreRequest: ClusterStoreRequest = {
    name: "",
    slug: "",
    description: "",
    sales_channel_id: 26,
    is_enabled: true,
    min: 1,
    max: 1,
  };

  mounted(): void {
    this.getChannels();
    this.messages = messages;
    this.module_channels = getFromStorage("MODULE_CHANNEL");
  }

  async getChannels() {
    this.loading = true;
    try {
      const response = await services.ChannelService.getAllChannelsWithoutPaginate();
      this.channels = response.results;
    } catch (e: any) {
      console.log("Error: ", e);
      this.serviceErro = true;
      this.text = e.message;
    } finally {
      this.loading = false;
    }
  }

  changeLoading(): void {
    this.loading = !this.loading;
  }

  closeSnack(): void {
    this.snackbar = false;
    this.$router.push("/clusters");
  }

  async store(event: any): Promise<void> {
    this.loading = true;
    this.text = "Salvando dados...";
    if (!this.clusterStoreRequest.name || !this.clusterStoreRequest.slug || !this.clusterStoreRequest.description || !this.clusterStoreRequest.sales_channel_id || !this.clusterStoreRequest.min || !this.clusterStoreRequest.max) {
      this.text = "Preencha todos os campos!";
    } else {
      try {
        const rawObject = toRaw(this.clusterStoreRequest);
        this.clusterStoreRequest.sales_channel_id = +this.module_channels;
        const response = await services.ClusterService.storeNewCluster(rawObject as ClusterStoreRequest);
        if (response.has_success) {
          this.text = messages.success;
          this.snackbar = true;
          this.btn_disabled = true;
        } else {
          this.snackbar = true;
          this.text = messages.fail;
        }
      } catch (e: any) {
        console.log("Error: ", e);
        this.serviceErro = true;
        this.text = e.message;
      } finally {
        setTimeout(() => {
          this.$router.push("/clusters");
        }, this.timeout);
        this.loading = false;
      }
    }
  }
}
</script>
<style lang="css">
.btn-action {
  margin-right: 1rem;
}
</style>
