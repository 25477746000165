<template>
  <div class="d-flex justify-space-between mb-6">
    <h2 class="title">Usuários</h2>
    <a href="/users/store" class="text-decoration-none">
      <v-btn class="text-white" color="movida-orange">
        <v-icon> mdi-plus </v-icon>
        Novo Usuário
      </v-btn>
    </a>
  </div>
  <v-row class="mb-3 flex justify-between">
    <v-col sm="3" md="4">
      <v-text-field label="Buscar..." prepend-inner-icon="mdi-magnify" clearable single-line density="compact" variant="solo" hide-details v-model="needleSearch" @input="searchByNeedle" @click:clear="clearFilter"></v-text-field>
    </v-col>
    <v-col sm="3" md="2">
      <div class="d-flex justify-space-between">
        <div class="d-flex align-center gap-4 mr-6">
          <v-menu transition="slide-y-transition">
            <template v-slot:activator="{ props }">
              <v-btn color="white" v-bind="props">
                Ordenar por:
                <v-icon left dark class="ml-1"> mdi-chevron-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, i) in headers" :key="i" :value="i">
                <v-list-item-title @click="changeSortSelected(item.key)">{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </v-col>
  </v-row>
  <v-data-table-server v-model:items-per-page="itemsPerPage" :headers="headers" :items="items" :loading="isLoadingTable" item-value="name" class="elevation-1" hide-default-footer>
    <template v-slot:bottom> </template>
    <template v-slot:[`item.isEnabled`]="{ item }">
      <div class="text-sm-body-2"> <span :class="{ 'bg-red': !!item.props.title.isEnabled, 'bg-green': !!item.props.title.isEnabled }" class="w-3 h-3 rounded-circle d-inline-block mr-2"></span>{{ !!item.props.title.isEnabled ? "Ativo" : "Inativo" }}</div>
    </template>
  </v-data-table-server>
  <v-row class="mt-2 d-flex align-center" justify="center">
    <div class="text-center">
      <v-pagination v-model="currentPage" :length="numberOfPages" :total-visible="7" @next="nextPage" @prev="prevPage" @update:modelValue="loadPage" color="movida-orange"></v-pagination>
      <div> {{ pageFrom }} a {{ pageTo }} de {{ totalOfRows }} resultados </div>
    </div>
  </v-row>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import * as services from "@/services";
import { User } from "@/models/user/User";
import { UserIndexWithPaginateResponse } from "@/models/user/UserIndexWithPaginateResponse";

@Options({
  components: {},
})
export default class UserIndexView extends Vue {
  public items: Array<User> = [];
  public currentPage = 0;
  public numberOfPages = 0;
  public itemsPerPage = 0;
  public totalOfRows = 0;
  public pageFrom = 0;
  public pageTo = 1;
  public headers = [
    {
      title: "Nome do usuário",
      align: "start",
      sortable: false,
      key: "name",
    },
    {
      title: "User ID",
      align: "end",
      sortable: false,
      key: "id",
    },
    {
      title: "Perfil atribuído",
      align: "end",
      sortable: false,
      key: "profileName",
    },
    {
      title: "Status",
      align: "end",
      sortable: false,
      key: "isEnabled",
    },
  ];
  public sortSelected = "name";
  public isLoadingTable = true;
  public needleSearch = null;
  mounted(): void {
    this.willMountDataPage();
  }
  async willMountDataPage(): Promise<void> {
    const { currentPage } = this;
    const response = await services.UserService.index(currentPage);
    this.unMountRequest(response);

    this.changeLoading();
  }
  nextPage(): void {
    this.changeLoading();
    this.index(this.currentPage, this.needleSearch, this.sortSelected);
  }
  prevPage(): void {
    this.changeLoading();
    this.index(this.currentPage, this.needleSearch, this.sortSelected);
  }
  loadPage(value: number): void {
    this.index(value, this.needleSearch, this.sortSelected);
    this.changeLoading();
  }
  changeLoading(): void {
    this.isLoadingTable = !this.isLoadingTable;
  }
  async index(page: number, needle: string | null = null, sortColumn: string | null = null) {
    try {
      if (this.isNullableSort(sortColumn)) {
        this.resetSortSelected();
        sortColumn = this.sortSelected;
      }
      const response = await services.UserService.index(page, needle, sortColumn);
      this.unMountRequest(response);
    } catch (e) {
      console.log("ERRO", e);
    }
    this.changeLoading();
  }
  private isNullableSort(sort: string | null) {
    return sort === null;
  }
  private resetSortSelected() {
    this.loadSortSelected();
  }
  private loadSortSelected() {
    this.sortSelected = this.headers[0].key;
  }
  private unMountRequest(response: UserIndexWithPaginateResponse) {
    if (response.results) {
      this.currentPage = response.results.current_page;
      this.items = response.results.data as Array<User>;
      this.numberOfPages = response.results.last_page;
      this.itemsPerPage = response.results.per_page;
      this.totalOfRows = response.results.total;
      this.pageFrom = response.results.from;
      this.pageTo = response.results.to;
    }
  }
  searchByNeedle() {
    if (this.needleSearch != null && Array.from(this.needleSearch).length > 3) {
      this.changeLoading();
      this.items = [];
      this.index(1, this.needleSearch);
    }
  }
  private clearFilter() {
    this.changeLoading();
    this.items = [];
    this.currentPage = 1;
    this.index(1);
  }
  changeSortSelected(itemSelected: string) {
    this.sortSelected = itemSelected;
    this.changeLoading();
    this.index(this.currentPage, null, itemSelected);
  }
}
</script>
<style lang="css">
.default-data-base {
  border: solid #efecec 0.4px;
}

.w-3 {
  width: 10px;
}

.h-3 {
  height: 10px;
}
</style>
@/models/user/UserIndexWithPaginateResponse
