import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/brand-light.svg'


const _hoisted_1 = { class: "d-flex justify-center align-center min-h-100vh" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_flex = _resolveComponent("v-flex")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[8] || (_cache[8] = _createElementVNode("div", { class: "logo" }, [
      _createElementVNode("img", {
        alt: "Logo Movida",
        src: _imports_0
      })
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_flex, { class: "justify-center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, {
                elevation: "5",
                class: "pa-md-16 pa-sm-4 px-md-16 px-sm-4 rounded-lg"
              }, {
                default: _withCtx(() => [
                  (_ctx.state.showErrorMessage)
                    ? (_openBlock(), _createBlock(_component_v_alert, {
                        key: 0,
                        closable: "",
                        density: "compact",
                        type: "error",
                        text: "Erro ao tentar fazer login!"
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_row, { "no-gutters": "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_container, null, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createElementVNode("header", null, [
                            _createElementVNode("h1", { class: "neutral-900" }, "Acesse o Gestão"),
                            _createElementVNode("p", { class: "neutral-300" }, "Para continuar, digite seu usuário e senha Movida.")
                          ], -1)
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_form, {
                    modelValue: _ctx.valid,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.valid) = $event)),
                    name: "form-login",
                    onSubmit: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.onSubmit()), ["prevent"]))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_container, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, {
                                cols: "12",
                                md: "12",
                                class: "py-4"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_text_field, {
                                    color: 'movida-orange',
                                    focused: true,
                                    disabled: _ctx.state.loading,
                                    modelValue: _ctx.form.username,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.username) = $event)),
                                    modelModifiers: { trim: true },
                                    type: "text",
                                    label: "Usuário",
                                    variant: "outlined",
                                    outlined: "",
                                    dense: "",
                                    rules: [_ctx.required]
                                  }, null, 8, ["disabled", "modelValue", "rules"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, {
                                cols: "12",
                                md: "12",
                                class: "py-0"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_text_field, {
                                    modelValue: _ctx.form.password,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.password) = $event)),
                                    modelModifiers: { trim: true },
                                    variant: "outlined",
                                    color: 'movida-orange',
                                    label: "Senha",
                                    "append-inner-icon": _ctx.state?.isVisiblePass ? 'mdi-eye-outline' : 'mdi-eye-off-outline',
                                    "onClick:appendInner": _cache[2] || (_cache[2] = () => (_ctx.state = { isVisiblePass: !_ctx.state?.isVisiblePass })),
                                    type: _ctx.state?.isVisiblePass ? 'text' : 'password',
                                    outlined: "",
                                    dense: "",
                                    rules: [_ctx.required],
                                    disabled: _ctx.state.loading
                                  }, null, 8, ["modelValue", "append-inner-icon", "type", "rules", "disabled"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, {
                                cols: "12",
                                md: "12",
                                class: "py-4 justify-center"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_btn, {
                                    disabled: _ctx.state.loading,
                                    loading: _ctx.state.loading,
                                    type: "submit",
                                    color: "movida-orange",
                                    size: "large",
                                    block: ""
                                  }, {
                                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                                      _createTextVNode(" Entrar ")
                                    ])),
                                    _: 1
                                  }, 8, ["disabled", "loading"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, {
                                cols: "12",
                                md: "12",
                                class: "py-0 text-center"
                              }, {
                                default: _withCtx(() => _cache[7] || (_cache[7] = [
                                  _createElementVNode("a", {
                                    class: "neutral-500 text-decoration-none font-weight-bold",
                                    title: "Esqueci minha senha",
                                    href: "/forgot-password"
                                  }, " Esqueci minha senha ", -1)
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ], 64))
}