import { BaseService } from "../BaseService";
import { ScheduleStoreRequest } from "@/models/schedule/ScheduleStoreRequest";

class ScheduleService extends BaseService {
  async getAllSchedule($channelId: number | null = null, page = 1, search: any = {}, from: string | null = null, to: string | null = null, sortColumn: string | null = "name") {
    let route = `/api/v1/schedule?CanalID=${$channelId}&page=${page}&column=${sortColumn}`;

    if (from && from !== "" && from !== undefined) {
      route = route + `&from=${from}`;
    }
    if (to && to !== "" && to !== undefined) {
      route = route + `&to=${to}`;
    }
    for (const [key, value] of Object.entries(search)) {
      if (value !== null && value !== "" && value !== undefined) {
        route = route + `&${key}=${value}`;
      }
    }

    const response = await this.instance.get(route, this.getConfigHeaders());
    return response.data;
  }

  async getAllBranch() {
    const route = `/api/v1/schedule/all/branch`;
    const response = await this.instance.get(route, this.getConfigHeaders());
    return response.data;
  }

  async getAllService() {
    const route = `/api/v1/schedule/all/service`;
    const response = await this.instance.get(route, this.getConfigHeaders());
    return response.data;
  }

  async getFilters() {
    const route = `/api/v1/schedule/filters`;
    const response = await this.instance.get(route, this.getConfigHeaders());
    return response.data;
  }

  async availability(branch: number | null, service: number | null) {
    const route = `/api/v1/schedule/availability?service_id=${service}&filial_id=${branch}`;
    const response = await this.instance.get(route, this.getConfigHeaders());
    return response.data;
  }

  async getSchedule(scheduleId: number, peopleId: number) {
    const route = `/api/v1/schedule/${scheduleId}?pessoa_id=${peopleId}`;
    const response = await this.get(route);
    return response.data;
  }

  async store(dataRequest: ScheduleStoreRequest) {
    const route = `/api/v1/schedule`;
    const response = await this.post(route, dataRequest);

    return response.data;
  }

  async import(file: FormData) {
    const route = `/api/v1/schedule/import`;
    const response = await this.instance.post(route, file, this.getConfigHeaders());

    return response.data;
  }

  async update(dataRequest: ScheduleStoreRequest, id: number) {
    const route = `/api/v1/schedule/${id}`;
    const response = await this.put(route, dataRequest);
    return response.data;
  }

  async destroy(id: number, peopleId: number) {
    const route = `/api/v1/schedule/${id}?pessoa_id=${peopleId}`;
    const response = await this.delete(route);
    return response.data;
  }
}

export default new ScheduleService("", "");
