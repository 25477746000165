<template>
  <v-card class="mb-5" :variant="'flat'">
    <v-row class="mb-0">
      <v-col>
        <p class="neutral-500 mb-n2">Catálogo</p>
        <h2 class="title">Configurar item</h2>
      </v-col>
      <v-col class="d-flex justify-end">
        <a class="text-decoration-none">
          <v-btn class="rounded-lg bg-neutral-100 neutral-700" size="large" :variant="'flat'" href="/catalog"> Voltar </v-btn>
        </a>
      </v-col>
    </v-row>
  </v-card>
  <v-row>
    <v-col cols="12">
      <v-card class="rounded-lg" :border="true" :variant="'flat'">
        <v-row no-gutters>
          <v-col cols="3">
            <v-row class="fill-height" no-gutters>
              <v-col cols="2">
                <v-avatar class="ma-2" rounded="0" size="190">
                  <v-img src="https://storage.googleapis.com/storage-vetor-zkm-dev/midias/zerokm/dev/vehicles-fipe/001558-0-image.webp"></v-img>
                </v-avatar>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="9">
            <v-row class="fill-height" no-gutters>
              <v-col cols="10">
                <v-container fluid>
                  <v-row no-gutters>
                    <v-col class="d-flex flex-column mt-4" cols="12">
                      <small class="neutral-500 font-weight-medium">Descritivo</small>
                      <strong class="neutral-700">{{ this.descritivo }}</strong>
                    </v-col>
                  </v-row>
                </v-container>
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-container fluid>
                      <v-row>
                        <v-col class="d-flex flex-column" cols="2">
                          <small class="neutral-500 font-weight-medium">Grupo</small>
                          <strong class="neutral-700">{{ this.grupo }}</strong>
                        </v-col>
                        <v-col class="d-flex flex-column" cols="2">
                          <small class="neutral-500 font-weight-medium">FIPE</small>
                          <strong class="neutral-700">{{ this.fipe }}</strong>
                        </v-col>
                        <v-col class="d-flex flex-column" cols="4">
                          <small class="neutral-500 font-weight-medium">Modelo</small>
                          <strong class="neutral-700">{{ this.modelo }}</strong>
                        </v-col>
                        <v-col class="d-flex flex-column" cols="2">
                          <small class="neutral-500 font-weight-medium">
                            <span
                              :class="{
                                'bg-red': allShowCases <= 0,
                                'bg-green': allShowCases > 0,
                              }"
                              class="w-3 h-3 rounded-circle d-inline-block mr-1"
                            ></span>
                            Ativos
                          </small>
                          <strong class="neutral-700">{{ allShowCases }}</strong>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
  <v-tabs v-model="activeTab" class="mt-4 neutral-700">
    <v-tab value="disponibilidade">Disponibilidade</v-tab>
    <v-tab value="caracteristicas">Características</v-tab>
    <v-tab value="precos">Preços</v-tab>
    <v-tab value="configuracoes-basicas">Configurações Básicas</v-tab>
  </v-tabs>
  <v-divider />
  <v-window v-model="activeTab">
    <v-window-item value="disponibilidade">
      <v-row justify="space-between" class="mb-3 mt-2">
        <v-col v-if="editAll">
          <v-col>
            <v-btn class="rounded-lg neutral-500 border-neutral-200" size="large" variant="outlined" prepend-icon="mdi-filter-variant" @click="compose"> Editar </v-btn>
          </v-col>
        </v-col>
        <v-col v-if="!editAll" md="2" sm="2">
          <v-col>
            <!-- eslint-disable-next-line prettier/prettier -->
            <v-text-field v-model="needleSearch"
              class="rounded-lg border-neutral-200 input-select"
              variant="outlined"
              clearable
              density="compact"
              hide-details
              label="Buscar..."
              prepend-inner-icon="mdi-magnify"
              single-line
              @input="searchByNeedle"
              @click:clear="clearFilter"
            ></v-text-field>
          </v-col>
        </v-col>
        <!-- Coluna do Botão -->
        <v-col cols="6" class="d-flex justify-end">
          <v-btn class="bg-red rounded-lg" :style="{ marginTop: '14px' }" elevation="0" @click="clearCacheSubChannel"> Limpar cache </v-btn>
        </v-col>
        <v-col cols="4" class="text-right d-flex justify-end">
          <v-col>
            <v-select
              class="select"
              v-model="selectedRegions"
              :items="regions"
              item-title="Regiao"
              item-value="RegiaoID"
              label="Região"
              persistent-hint
              single-line
              multiple
              chips
              variant="outlined"
              hide-details
              density="compact"
              :color="'movida-orange'"
              @update:modelValue="changeRegionSelected"
              no-data-text="Carregando regiões..."
            />
          </v-col>
          <v-col>
            <v-select v-model="selectedOrder" :items="headersOrdenar" item-title="title" item-value="key" label="Ordenar lista" persistent-hint single-line variant="outlined" hide-details density="compact" :color="'movida-orange'" @update:modelValue="changeOrderSelected" />
          </v-col>
        </v-col>
      </v-row>
      <v-data-table-server v-model="selected" :headers="headers" :items="filiais" class="rounded-lg neutral-500" :loading="isLoadingTable" hide-default-footer fixed-header item-value="name" show-select @change="changeRoute">
        <template v-slot:[`item.name`]="{ item }">
          <div class="text-sm-body-2"> {{ item.value.Loja }}</div>
        </template>
        <template v-slot:[`item.showCases`]="{ item }">
          <div class="text-sm-body-2">
            <span
              :class="{
                'bg-red': !item.value.showCases || (item.value.showCases.is_enabled !== '1' && item.value.showCases.is_enabled !== 'true'),
                'bg-green': item.value.showCases && (item.value.showCases.is_enabled === '1' || item.value.showCases.is_enabled === 'true'),
              }"
              class="w-3 h-3 rounded-circle d-inline-block mr-2"
            ></span>
            {{ item.value.showCases && (item.value.showCases.is_enabled === "1" || item.value.showCases.is_enabled === "true") ? "Ativo" : item.value.showCases && item.value.showCases.is_enabled === "0" ? "Inativo" : "Inativo" }}
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn class="rounded-lg neutral-500" border="neutral-200" :variant="'outlined'" @click="composeWithItem(item.value)">Editar</v-btn>
        </template>
        <template v-slot:bottom></template>
      </v-data-table-server>
      <v-row v-if="!showCases" class="mt-2 d-flex align-center" justify="center">
        <div class="text-center mt-2 mb-3">
          <v-pagination v-model="currentPage" :length="numberOfPages" :total-visible="7" @next="nextPage" @prev="prevPage" @update:modelValue="loadPage"></v-pagination>
          <div class="neutral-300 mt-1 pb-1 font-regular"> {{ pageFrom }} a {{ pageTo }} de {{ totalOfRows }} resultados</div>
        </div>
      </v-row>
      <v-dialog v-model="dialogCompose" width="608">
        <v-card class="py-2">
          <v-card-title class="font-weight-bold ml-4 py-2" primary-title> Editar</v-card-title>
          <v-divider class="mx-5"></v-divider>
          <v-card-text class="mt-n3">
            <v-form ref="sendForm" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <div class="d-flex flex-no-wrap">
                    <div class="v-col-8">
                      <v-card-title class="neutral-700 font-weight-bold text-body-1">Encomendas</v-card-title>
                      <v-card-subtitle v-if="isEnabled" class="font-weight-medium neutral-500 mt-n3">Ativado</v-card-subtitle>
                      <v-card-subtitle v-if="!isEnabled" class="font-weight-medium neutral-500 mt-n3">Desativado</v-card-subtitle>
                    </div>
                    <div class="v-col-4 offset-2">
                      <v-switch v-model="isEnabled" color="#FF5000" inset></v-switch>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <div v-if="isEnabled">
                <v-card class="border-neutral-200 rounded-lg pb-3" elevation="0" theme="white">
                  <div class="d-flex">
                    <div class="v-col-8">
                      <v-card-title class="neutral-700 font-weight-bold text-body-1">Cores sob encomenda</v-card-title>
                      <v-card-subtitle v-if="isEnabledColor" class="font-weight-medium neutral-500 mt-n3">Personalizado</v-card-subtitle>
                      <v-card-subtitle v-if="!isEnabledColor" class="font-weight-medium neutral-500 mt-n3">Padrão (Vetor)</v-card-subtitle>
                    </div>
                    <div class="v-col-4 offset-2">
                      <v-switch class="mt-2" v-model="isEnabledColor" color="#FF5000" inset></v-switch>
                    </div>
                  </div>
                  <div v-if="isEnabledColor">
                    <v-divider class="mx-3 mb-5"></v-divider>
                    <v-row v-for="(colorId, index) in colors" :key="index" class="space-between">
                      <v-col class="d-flex justify-center" cols="2">
                        <v-btn :color="getColor(index)" class="rounded-circle d-inline-block btn-circle ml-5" @click="valueIndex(index)"></v-btn>
                      </v-col>
                      <v-col cols="10" class="d-flex align-center">
                        <v-text-field v-model="colorsName[index]" class="rounded-lg input-color" persistent-hint variant="outlined"></v-text-field>
                        <v-btn size="large" class="neutral-500 align-self-center mt-2" variant="text" @click="removeColor(index)">
                          <v-icon size="x-large" icon="mdi-delete-outline"></v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="2"> </v-col>
                    </v-row>
                    <div class="d-flex justify-start mt-4">
                      <div class="v-col-8">
                        <v-btn size="small" class="neutral-500" variant="text" @click="addColor('#FFFFFF')">
                          <v-icon icon="mdi-plus-circle-outline mr-2"></v-icon>
                          Adicionar cor
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </v-card>
                <v-row class="mt-4">
                  <v-col cols="8" class="d-flex align-center align-stretch">
                    <v-card-title class="neutral-700 font-weight-bold text-body-1 align-self-center mt-n3">Prazo para encomendas</v-card-title>
                  </v-col>
                  <v-col cols="4" class="d-flex justify-center align-stretch mb-5">
                    <v-select v-model="delivery_time" :items="items" persistent-hint variant="outlined" class="rounded-lg neutral-500" density="compact" hide-details :color="'movida-orange'" no-data-text="Carregando prazos..."></v-select>
                  </v-col>
                </v-row>
              </div>
              <v-divider></v-divider>
              <v-row class="mt-2">
                <v-col class="d-flex justify-end">
                  <v-btn class="mr-2 border-neutral-200 neutral-500 rounded-lg" size="large" @click="cancel()" variant="outlined"> Cancelar</v-btn>
                  <v-btn class="bg-movida-orange rounded-lg" elevation="0" size="large" @click="saveCatalog()"> Salvar</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogColor" width="auto">
        <v-card>
          <v-color-picker v-model="selectedColor" mode="hex"></v-color-picker>
          <v-card-actions>
            <v-btn class="bg-movida-orange rounded-lg my-2" elevation="0" @click="saveColor()" block>Confirmar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar v-model="snackbar" :timeout="timeout">
        <v-icon class="ml-1"> mdi-information-outline</v-icon>
        {{ text }}
        <template v-slot:actions>
          <v-btn variant="text" @click="closeSnack"> Fechar</v-btn>
        </template>
      </v-snackbar>
    </v-window-item>
    <v-window-item class="mt-3" value="precos">
      <v-container>
        <v-row align="center" justify="space-between">
          <!-- Coluna do Formulário -->
          <v-col cols="12" md="5">
            <v-row>
              <v-col>
                <v-label class="label-title" for="price_starting">A partir de:</v-label>
                <money3 class="money rounded-lg" v-model="price_starting" v-bind="money"></money3>
                <!-- <v-text-field variant="outlined" v-model="price_starting" v-maska:[options] data-maska="0009.99" data-maska-tokens="0:\d|9:\d:optional" @input="updatePriceStarting($event.target.value)"> </v-text-field> -->
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-label class="label-title" for="price_promotional">Valor Promocional:</v-label>
                <money3 class="money rounded-lg" v-model="price_promotional" v-bind="money"></money3>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-btn :disabled="!isPriceStartingFilled" class="bg-movida-orange rounded-lg" elevation="0" @click="catalogPricingStore">Salvar</v-btn>
              </v-col>
            </v-row>
          </v-col>

          <!-- Coluna do Botão -->
          <v-col cols="12" md="2">
            <v-btn class="bg-red rounded-lg" elevation="0" @click="clearPriceCacheSync"> Limpar cache </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-window-item>
    <v-window-item class="mt-3" value="caracteristicas">
      <v-container>
        <v-row>
          <v-card :style="{ boxShadow: 'none', width: '30%' }">
            <v-card-title>Tags</v-card-title>
            <v-card-subtitle>Crie novas tags</v-card-subtitle>
            <v-col class="col-4 align-center">
              <v-row>
                <v-col cols="8">
                  <v-text-field v-model="nameCatalogTag" clearable color="#F47920" hide-details="auto" label="Nova Tag" variant="outlined"></v-text-field>
                </v-col>
              </v-row>
              <v-btn :disabled="btn_disabled" class="bg-movida-orange rounded-lg mt-4" elevation="0" @click="validateTag">Criar tag</v-btn>
            </v-col>
          </v-card>
          <v-card :style="{ boxShadow: 'none', width: '50%' }">
            <v-card-title>Grupo de tags</v-card-title>
            <v-card-subtitle>Crie novos grupos de tags</v-card-subtitle>
            <v-col class="col-6 align-center">
              <v-row>
                <v-col cols="6">
                  <v-select v-model="selectedTags" :items="tagsList" chips color="#F47920" item-title="name" item-value="id" label="Tags" multiple></v-select>
                </v-col>
                <v-col cols="6">
                  <v-switch v-if="showFieldIsEnabledTagsGroup" v-model="isEnabledGroupTags" color="#FF5000" inset label="Ativado" @click="disabledAndEnabledGroupTags"></v-switch>
                </v-col>
              </v-row>
              <v-btn :disabled="btn_disabled" class="bg-movida-orange rounded-lg" elevation="0" @click="groupTagsStore()"> Salvar grupo</v-btn>
            </v-col>
          </v-card>
        </v-row>
      </v-container>
    </v-window-item>
    <v-window-item class="mt-3" value="configuracoes-basicas">
      <v-container class="ma-0 pa-0">
        <v-card>
          <div class="d-flex flex-row">
            <v-tabs v-model="tabConfigs" direction="vertical" class="neutral-700">
              <v-tab text="Fipe Correspondente" value="fip-correspondente">Carro Provisório</v-tab>
              <v-tab text="Plataforma" value="plataforma">Plataforma</v-tab>
            </v-tabs>
            <v-window v-model="tabConfigs" class="tabs-config">
              <v-window-item value="fip-correspondente">
                <v-row align="center" justify="space-between">
                  <v-card flat :style="{ boxShadow: 'none', width: '100%', padding: '24px' }">
                    <v-card-title>Grupos RAC correspondentes</v-card-title>
                    <v-card-subtitle> Selecione o veículo correspondente do grupo RAC para reservas do Carro Provisório </v-card-subtitle>
                    <v-row v-if="!state.loading && state?.allItems?.length" class="mt-4">
                      <v-col>
                        <v-select v-model="state.selectedItemNames" :items="itemNames" label="Selecionar Itens" multiple chips :menu-props="{ closeOnContentClick: false }" ref="selectInput">
                          <template v-slot:selection="data">
                            <v-chip :key="data.item" close @click:close="removeItem(data.item)">
                              {{ data.item }}
                            </v-chip>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-col cols="12" md="12" class="mb-6">
                      <v-btn :disabled="state.loading" class="bg-movida-orange rounded-lg float-right mt-2" elevation="0" @click="onClickSaveConfig()">Salvar</v-btn>
                      <v-divider></v-divider>
                    </v-col>
                    <v-card-subtitle v-if="!state.loading && state?.allItems?.length && state?.catalogMap?.length > 0"> Itens já estão vinculados </v-card-subtitle>
                    <v-table theme="white" v-if="!state.loading && state?.allItems?.length && state?.catalogMap?.length > 0">
                      <thead>
                        <tr>
                          <th class="text-left"> De </th>
                          <th class="text-left"> Para </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in state.catalogMap" :key="item.createdAt">
                          <td>Grupo: {{ item?.catalogFromData?.catalogGroup }} | Código FIPE - {{ item?.catalogFromData?.fipeCode }}</td>
                          <td>Grupo: {{ item?.catalogToData?.catalogGroup }} | Código FIPE - {{ item?.catalogToData?.fipeCode }}</td>
                        </tr>
                      </tbody>
                    </v-table>
                    <v-row v-if="!state.loading && !state?.allItems?.length">
                      <v-sheet class="d-flex align-center justify-center pa-8">
                        <v-chip> Nenhum item disponível </v-chip>
                      </v-sheet>
                    </v-row>
                    <v-row v-if="state.loading">
                      <v-sheet class="d-flex align-center justify-center pa-8">
                        <v-chip> Carregando... </v-chip>
                      </v-sheet>
                    </v-row>
                  </v-card>
                </v-row>
              </v-window-item>
              <v-window-item value="plataforma">
                <v-container>
                  <v-row align="center" justify="space-between">
                    <v-card :style="{ boxShadow: 'none', width: '100%' }">
                      <v-card-title>Subcanal</v-card-title>
                      <v-card-subtitle>Selecionar subcanal</v-card-subtitle>
                      <v-row>
                        <v-col cols="12">
                          <v-select v-model="selectedSubchannels" :items="subchannelsList" chips color="#F47920" item-title="name" item-value="id" label="Subcanais" multiple></v-select>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-col cols="12" md="12">
                      <v-divider></v-divider>
                      <v-btn :disabled="btn_disabled" class="bg-movida-orange rounded-lg float-right mt-2" elevation="0" @click="subchannelsStore()"> Salvar</v-btn>
                      <v-btn class="bg-red rounded-lg float-right mt-2 ml-2 mr-1" elevation="0" @click="clearCacheSubChannel"> Limpar cache </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-window-item>
            </v-window>
          </div>
        </v-card>
      </v-container>
    </v-window-item>
  </v-window>
  <v-snackbar v-model="snackbar" :timeout="timeout">
    <v-icon class="ml-1"> mdi-information-outline </v-icon> {{ text }}
    <template v-slot:actions>
      <v-btn variant="text" @click="closeSnack"> Fechar </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import * as services from "@/services";
import messages from "@/constants/messages";
import { ref } from "vue";
import { Branch } from "@/models/branch/Branch";
import { BranchStoreRequest } from "@/models/branch/BranchStoreRequest";
import { Money3Component } from "v-money3";
import { CatalogPricingRequestDTO } from "@/models/catalog/catalog-pricing/CatalogPricingStoreRequest";
import { CatalogTagStoreRequest } from "@/models/catalogTag/CatalogTagStoreRequest";
import { CatalogGroupTagStoreRequest } from "@/models/catalogTag/CatalogGroupTagStoreRequest";
import { CatalogRegionsRequest } from "@/models/catalog/CatalogRegionsRequest";
import { getFromStorage } from "@/utils/localStorage";
import { ICatalog, ICatalogMap, IStoreCatalogItem } from "@/models/catalog/Catalog";

interface Result {
  id: number;
  sale_channel_id: number | null;
  name: string;
  is_enabled: number;
  created_at: string;
  updated_at: string | null;
  deleted_at: string | null;
  isEnabled: number;
}

interface Order {
  title: string;
  key: string;
  align?: string;
  sortable?: boolean;
}

interface Item {
  name: string;
}

interface State {
  allItems?: Array<ICatalog>;
  loading: boolean;
  items: Array<Item>;
  selectedItemNames: Array<string>;
  catalogMap: Array<ICatalogMap>;
}

@Options({
  components: { money3: Money3Component },
})
export default class CatalogView extends Vue {
  $route: any;
  public editAll = false;
  public subject: any;
  public usuario: any;
  public delivery_time: any = "";
  public isEnabled = ref(false);
  public isEnabledColor = ref(false);
  public to: any;
  public branchTypeID = "";
  public moduleSelectedId = "";
  public from: any;
  public rules = [(value: any) => !!value || "Required.", (value: string) => (value && value.length >= 3) || "Min 3 characters"];
  public nome = "";
  public messages = {};
  public description = "";
  public orderSelected = "";
  public sortedBy = "asc";
  public isLoadingTable = true;
  public needleSearch = null;
  public id: any = 0;
  public filialID = 0;
  public snackbar = false;
  public text = "";
  public timeout = 10000;
  public btn_disabled = false;
  public selected = [];
  public filialIds = [];
  public numberOfPages = 0;
  public itemsPerPage = 0;
  public dialogCompose = false;
  public editAllRequisition = false;
  public dialogColor = false;
  public activeMessage = {};
  public composeMessage = {};
  public valid = true;
  public currentPage = 1;
  public totalOfRows = 0;
  public pageFrom = 0;
  public pageTo = 1;
  public selectedItems = {};
  public item: any = {};
  public items = [];
  public showCases = false;
  public allShowCases = 0;
  public RegiaoIDs = "";
  public selectedRegions = [];
  public selectedOrder = "Ordenar lista";
  public headers = [
    {
      title: "Filial",
      align: "start",
      sortable: false,
      key: "Loja",
    },
    {
      title: "Encomendas",
      key: "showCases",
      sortable: false,
    },
    {
      title: "Ações",
      key: "actions",
      sortable: false,
    },
  ];
  public sortBy = ref([]);
  public tabConfigs: string | null = null;
  public headersOrdenar = [
    { title: "Recentes", align: "start", key: "FilialID", sortable: false },
    { title: "Filial (A-Z)", align: "start", key: "Loja", sortable: false },
    { title: "Encomendas (Ativo)", align: "start", key: "showCases", sortable: false },
  ];

  public descritivo = "";
  public grupo = "";
  public fipe = "";
  public modelo = "";
  public carroProvisorio = 288;

  public filiais: Array<Branch> = [];
  public regions: Array<CatalogRegionsRequest> = [];

  public colors = ["#000000"];
  public colorsName = [];
  public index = 0;

  public selectedColor = ""; // cor selecionada
  public price_starting = "";
  public price_promotional: string | null = null;
  public catalogPricingID = null;
  public catalogShowCaseId = null;

  public money = {
    prefix: "R$ ",
    suffix: "",
    thousands: ".",
    decimal: ",",
    precision: 2,
  };

  activeTab: string | null = null;
  inputValue = "";

  nameCatalogTag = "";
  isEnabledCatalogTag = true;

  catalogTagId = 0;
  groupTagsValue = [];
  isEnabledGroupTags = true;

  tagsList = [];
  tagsListByGroup = [];
  selectedTags: Array<number> = [];
  showFieldIsEnabledTagsGroup = false;
  showCaseId = null;
  subchannelsList: Result[] = [];
  selectedSubchannels: Array<number> = [];

  public state: State = {
    items: [],
    allItems: [],
    loading: true,
    selectedItemNames: [],
    catalogMap: [],
  };
  public itemNames: string[] = [];

  get isPriceStartingFilled(): boolean {
    return !!this.price_starting && this.price_starting !== "0.00";
  }

  /**
   * @param newState
   * @private
   */
  private setState(newState: Partial<State>) {
    this.state = {
      ...this.state,
      ...newState,
    };
    if (newState.items !== undefined) {
      this.itemNames = newState.items.map((item) => item.name);
    }
  }

  changeRoute(): void {
    const allSelected = this.selected.length === this.filiais.length;

    this.selected.forEach((item) => {
      this.filialIds.push(item["FilialID"]);
    });

    if (allSelected) {
      this.editAll = true;
    } else {
      this.editAll = false;
      this.filialIds = [];
    }
  }

  loadItems({ page, sortBy }: { page: number; sortBy: Array<{ key: string; order: string }> }): void {
    if (sortBy.length) {
      this.orderSelected = sortBy[0].key;
      this.sortedBy = sortBy[0].order;
    } else {
      this.orderSelected = "";
      this.sortedBy = "";
    }
    this.loadPage(page);
  }

  addColor(color: string): void {
    this.dialogColor = false;
    this.colors.push(color);
  }

  updatePriceStarting(value: string) {
    this.price_starting = value;
  }
  updatePricePromotional(value: string | null) {
    this.price_promotional = value;
  }

  saveColor(): void {
    this.dialogColor = false;
    this.colors[this.index] = this.selectedColor;
    this.selectedColor = "";
  }

  removeColor(index: number): void {
    this.colors.splice(index, 1);
    this.colorsName.splice(index, 1);
  }

  getColor(index: number): string {
    return this.colors[index];
  }

  getColorName() {
    return (index: any) => {
      return this.colorsName[index] ? this.colorsName[index][0] : "";
    };
  }

  valueIndex(index: number): void {
    this.index = index;
    this.selectedColor = this.colors[index];
    this.dialogColor = true;
  }

  clearSelected(): void {
    this.selected = [];
    this.filialIds = [];
    this.editAll = false;
  }

  loadPage(value: number): void {
    this.changeLoading();
    this.clearSelected();
    this.getBranchListByActive(this.needleSearch);
    this.getBranchList(value, this.needleSearch);
    this.scrollToTop();
  }

  nextPage(): void {
    this.loadPage(this.currentPage);
  }

  prevPage(): void {
    this.loadPage(this.currentPage);
  }

  searchByNeedle() {
    if (this.needleSearch != null && Array.from(this.needleSearch).length > 3) {
      this.changeLoading();
      this.getBranchList(1, this.needleSearch);
      this.getBranchListByActive(this.needleSearch);
    }
  }

  cancel() {
    this.dialogCompose = false;
  }

  async saveCatalog() {
    this.usuario = getFromStorage("DATA_USER");
    const usuario_id = JSON.parse(this.usuario).id;

    this.dialogCompose = false;

    if (!usuario_id) {
      return;
    }

    if (this.editAllRequisition) {
      const storeRequest: BranchStoreRequest = {
        UserId: usuario_id,
        FilialID: this.filialIds,
        CatalogGroup: this.branchTypeID,
        DeliveryTime: this.delivery_time,
        colors: this.colors,
        colorsName: this.colorsName,
        isEnabled: this.isEnabled,
        isEnabledColors: this.isEnabledColor,
        fipe: this.fipe,
      };

      const response = await services.CatalogService.store(storeRequest);

      if (response.has_success) {
        this.text = messages.success;
        this.snackbar = true;
        this.btn_disabled = true;
        this.loadPage(this.currentPage);
      } else {
        this.snackbar = true;
        this.text = messages.fail;
      }
    } else {
      const storeRequest: BranchStoreRequest = {
        UserId: usuario_id,
        FilialID: this.filialID,
        CatalogGroup: this.branchTypeID,
        DeliveryTime: this.delivery_time,
        colors: this.colors,
        colorsName: this.colorsName,
        isEnabled: this.isEnabled,
        isEnabledColors: this.isEnabledColor,
        fipe: this.fipe,
      };

      const response = await services.CatalogService.store(storeRequest);

      if (response.has_success) {
        this.text = messages.success;
        this.snackbar = true;
        this.btn_disabled = true;
        this.loadPage(this.currentPage);
      } else {
        this.snackbar = true;
        this.text = messages.fail;
      }
    }
  }

  compose(): void {
    this.colors = ["#000000"];
    this.colorsName = [];
    this.isEnabled = ref(false);
    this.isEnabledColor = ref(false);
    this.delivery_time = this.items[0]["title"];

    this.dialogCompose = true;

    this.editAllRequisition = true;
  }

  async composeWithItem(item: Branch): Promise<void> {
    this.editAllRequisition = false;
    this.filialID = item.FilialID;

    const response = await services.CatalogService.showCaseByLocationAndGroup(this.filialID, this.branchTypeID);

    if (response.results.data.length > 0) {
      this.setDeliveryTime(response.results.data[0].location[0].delivery_time_id);
      this.colors = response.results.data[0].location[0].colors.map((color: any) => color.color_id);
      this.isEnabled = ref(Boolean(Number(response.results.data[0].location[0].is_enabled)));
      this.isEnabledColor = ref(Boolean(Number(response.results.data[0].location[0].colors[0]?.color_is_enabled)));
      this.colorsName = response.results.data[0].location[0].colors.map((color: any) => color.color_name);
    } else {
      this.colors = ["#000000"];
      this.colorsName = [];
      this.isEnabled = ref(false);
      this.isEnabledColor = ref(false);
    }

    this.dialogCompose = true;
  }

  mounted(): void {
    this.id = String(this.$route.params.id);
    this.descritivo = String(this.$route.query.descritivo).slice(0, 150);
    this.grupo = String(this.$route.query.grupoId);
    this.fipe = String(this.$route.query.fipe);
    this.modelo = String(this.$route.query.modelo);

    this.messages = messages;

    const asyncFunctions = [this.getDeliveryTime(), this.getCatalogPricing(), this.getRegions()];

    Promise.all(asyncFunctions)
      .then(() => {
        this.willMountDataPage();
      })
      .catch((error) => {
        console.error("Erro durante a execução das funções assíncronas iniciais:", error);
      });
  }

  setDeliveryTime($delivery_time_id: string): void {
    this.items.forEach((item) => {
      Object.keys(item).forEach((key: any) => {
        if (item[key] == $delivery_time_id) {
          this.delivery_time = item["title"];
        }
      });
    });
  }

  async willMountDataPage(): Promise<void> {
    this.changeLoading();
    const { currentPage } = this;
    this.branchTypeID = this.$route.params.id.toString();
    this.moduleSelectedId = getFromStorage("KEY_MODULE_SELECTED") || "";

    const response = await services.CatalogService.getAllBranch(this.moduleSelectedId, this.branchTypeID, currentPage);
    this.getInfoShowCase();
    this.getCatalogTags();
    this.getGroupTagsByGroupVehicle();
    this.getSubchannels();
    this.getAllCatalog();
    this.unMountRequest(response);

    const actives = await services.CatalogService.getAllBranchByActive(this.moduleSelectedId, this.branchTypeID, "", true);
    this.allShowCases = actives.results?.data?.length;
  }

  async getDeliveryTime(): Promise<void> {
    const response = await services.CatalogService.getDeliveryTime();

    const { data } = response.results || []; // Desestruturação

    this.items = data.map((item: any) => {
      return {
        key: item.id,
        title: item.qty + " " + item.unit_of_measurement,
      };
    });

    this.delivery_time = this.items[0]["title"];
  }

  async getCatalogPricing(): Promise<void> {
    const response = await services.CatalogPricingService.getCatalogPricing(this.grupo, this.fipe);
    if (response.has_success) {
      this.price_starting = response.results.price_starting;
      this.price_promotional = response.results.price_promotional;
      this.price_promotional = this.price_promotional !== null ? this.price_promotional : null;
      this.catalogPricingID = response.results.catalog_id;
    }
  }

  async catalogPricingStore(): Promise<void> {
    this.usuario = getFromStorage("DATA_USER");
    const storeRequest: CatalogPricingRequestDTO = {
      catalog_pricing: {
        price_starting: parseFloat(this.price_starting),
        price_promotional: this.price_promotional !== null ? parseFloat(this.price_promotional) : null,
        catalog_id: this.catalogPricingID !== undefined ? this.catalogPricingID : this.catalogShowCaseId,
      },
      catalog: {
        offer_type: null,
        operator_id: JSON.parse(this.usuario).id,
        is_enabled: true,
        fipe_code: this.fipe,
        catalog_group: this.grupo,
      },
    };

    const response = await services.CatalogPricingService.store(storeRequest);

    if (response.has_success) {
      this.snackbar = true;
      this.text = response.message;
    } else {
      this.snackbar = true;
      this.text = messages.fail;
    }
  }

  async getRegions(): Promise<void> {
    const response = await services.CatalogService.getRegions(this.moduleSelectedId, this.branchTypeID);
    if (response.has_success) {
      this.regions = response.results.data;
    }
  }

  changeLoading(): void {
    this.isLoadingTable = !this.isLoadingTable;
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  closeSnack(): void {
    this.snackbar = false;
    // this.$router.go(0);
  }

  changeOrderSelected() {
    // Retornar por IDs mais recentes
    if (this.selectedOrder === "FilialID") {
      this.sortedBy = "desc";
    } else {
      this.sortedBy = "asc";
    }

    // Retornar quando ativos
    if (this.selectedOrder === "showCases") {
      this.sortedBy = "";
      this.orderSelected = "";
      this.showCases = true;
    } else {
      this.showCases = false;
      this.orderSelected = this.selectedOrder;
    }

    this.changeLoading();
    this.getBranchList(this.currentPage, null);
  }

  changeRegionSelected(): void {
    const regions = Object.values(this.selectedRegions);
    this.RegiaoIDs = regions.join(",");
    this.changeLoading();
    this.getBranchList(this.currentPage, null);
  }

  async getBranchList(page: number, needle: string | null = null): Promise<void> {
    try {
      const response = await services.CatalogService.getAllBranch(this.moduleSelectedId, this.branchTypeID, page, needle, this.orderSelected, this.sortedBy, this.showCases, this.RegiaoIDs);
      this.unMountRequest(response);
    } catch (e) {
      console.log("ERRO", e);
    }
    this.changeLoading();
  }

  async getBranchListByActive(needle: string | null = null): Promise<void> {
    try {
      const response = await services.CatalogService.getAllBranchByActive(this.moduleSelectedId, this.branchTypeID, needle, true);
      this.allShowCases = response.results?.data?.length;
    } catch (e) {
      console.log("ERRO", e);
    }
  }

  public validateTag(): void {
    if (this.countCharacters(this.nameCatalogTag) > 40 || this.countCharacters(this.nameCatalogTag) == 0) {
      this.text = "Tag em branco ou com mais de 40 caracteres não é permitido";
      this.snackbar = true;
    } else {
      this.btn_disabled = true;
      this.tagStore();
    }
  }

  async tagStore(): Promise<void> {
    const storeRequest: CatalogTagStoreRequest = {
      name: this.nameCatalogTag.toUpperCase(),
      is_enabled: this.isEnabledCatalogTag,
    };

    if (!this.checkIfTheTagExists(this.nameCatalogTag.toUpperCase())) {
      const response = await services.CatalogTagService.store(storeRequest);
      if (response.has_success) {
        this.snackbar = true;
        this.text = messages.success;
        this.btn_disabled = false;
        this.nameCatalogTag = "";
        this.getCatalogTags();
      } else {
        this.snackbar = true;
        this.btn_disabled = false;
        this.text = messages.fail;
      }
    } else {
      this.btn_disabled = false;
      this.snackbar = true;
      this.text = "Tag já cadastrada";
    }
  }

  async subchannelsStore(): Promise<void> {
    const catalog_id = this.catalogShowCaseId;
    const subChannelsRequest = {
      subchannel_ids: {
        ...this.selectedSubchannels,
      },
    };

    if (catalog_id) {
      let response;
      response = await services.SubchannelsService.updateAndStoreSubchannels(catalog_id, { subchannel_ids: [0] });

      if (response.has_success) {
        this.snackbar = true;
        this.text = response.message;
      }

      if (this.selectedSubchannels.length > 0) {
        response = await services.SubchannelsService.updateAndStoreSubchannels(catalog_id, subChannelsRequest);

        if (response.has_success) {
          this.snackbar = true;
          this.text = response.message;
        } else {
          this.snackbar = true;
          this.text = messages.fail;
        }
      }

      await services.CatalogService.clearCatalogSync();
    }
  }

  async clearCacheSubChannel(): Promise<void> {
    const response = await services.CatalogService.clearCatalogSync();

    if (response.has_success) {
      this.snackbar = true;
      this.text = response.message;
    } else {
      this.snackbar = true;
      this.text = messages.fail;
    }
  }

  async groupTagsStore(): Promise<void> {
    this.btn_disabled = true;

    this.usuario = getFromStorage("DATA_USER");
    const usuario_id = JSON.parse(this.usuario).id;

    const storeRequest: CatalogGroupTagStoreRequest = {
      user_id: usuario_id,
      fipe_code: this.fipe,
      catalog_group: this.grupo,
      catalog_tag_ids: this.selectedTags,
      is_enabled: this.isEnabledGroupTags,
    };

    if (this.showCaseId) {
      await services.CatalogGroupTagService.deleteGroupsTagsByCatalogIdBeforeStore(this.showCaseId);
    }

    const response = await services.CatalogGroupTagService.store(storeRequest);

    if (response.has_success) {
      this.showFieldIsEnabledTagsGroup = true;
      this.snackbar = true;
      this.text = response.message;
      this.btn_disabled = false;
    } else {
      this.snackbar = true;
      this.text = messages.fail;
    }
  }

  async clearPriceCacheSync(): Promise<void> {
    let data = {
      tag: this.grupo + "MO",
    };
    await services.CatalogCacheService.cacheListByTag(data);
    const response = await services.CatalogCacheService.purgeTagCache(data);
    if (response.has_success) {
      this.snackbar = true;
      this.text = response.message;
    } else {
      this.snackbar = true;
      this.text = messages.fail;
    }
  }

  async getCatalogTags(): Promise<void> {
    try {
      const response = await services.CatalogTagService.getCatalogTags();
      this.tagsList = response.results;
    } catch (e) {
      console.log("ERRO", e);
    }
  }

  async getInfoShowCase(): Promise<void> {
    try {
      const response = await services.CatalogService.showCaseByFipeCodeAndGroup(this.fipe, this.grupo);
      this.catalogShowCaseId = response.results[0].id;
    } catch (e) {
      console.log("ERRO", e);
    }
  }

  async getSubchannels(): Promise<void> {
    try {
      const response = await services.SubchannelsService.getAllSubchannelsWithoutPaginate();
      if (this.catalogShowCaseId) {
        let resp = await services.SubchannelsService.getSubchannelsSelected(this.catalogShowCaseId);
        this.selectedSubchannels = resp.results.data;
      }
      const moduleChannel = Number(localStorage.getItem("MODULE_CHANNEL"));
      const filteredResults: Result[] = response.results.filter((item: Result) => item.sale_channel_id == moduleChannel);
      this.subchannelsList = filteredResults;
    } catch (e) {
      console.log("ERRO", e);
    }
  }

  async disabledAndEnabledGroupTags(): Promise<void> {
    try {
      const storeRequest: CatalogGroupTagStoreRequest = {
        catalog_id: this.showCaseId,
      };
      const response = await services.CatalogGroupTagService.enableAndDisableGroupTags(storeRequest);
      if (response.has_success) {
        this.snackbar = true;
        this.text = response.message;
      } else {
        this.snackbar = true;
        this.text = messages.fail;
      }
    } catch (e) {
      console.log("ERRO", e);
    }
  }

  async getGroupTagsByGroupVehicle(): Promise<void> {
    try {
      const response = await services.CatalogGroupTagService.getGroupTagsByGroupVehicle(this.grupo);
      this.tagsListByGroup = response.results;
      const preencheGroupTags = (tags: Array<any>) => {
        // eslint-disable-next-line prettier/prettier
        if (tags.length) {
          this.showFieldIsEnabledTagsGroup = true;
          tags.forEach((item) => {
            if (item.is_enabled === 0) {
              this.isEnabledGroupTags = false;
            }
            this.showCaseId = item.catalog_id;
            this.selectedTags.push(+item.catalog_tag_id);
          });
        }
      };

      preencheGroupTags(this.tagsListByGroup);
    } catch (e) {
      console.log("ERRO", e);
    }
  }

  public clearFilter(): void {
    this.changeLoading();
    this.currentPage = 1;
    this.getBranchList(1);
    this.getBranchListByActive("");
  }

  private unMountRequest(response: any): void {
    if (response.results) {
      this.filiais = response.results.data as Array<Branch>;

      if (!this.showCases) {
        this.numberOfPages = response.results.meta.pagination.total_pages;
        this.itemsPerPage = response.results.meta.pagination.per_page;
        this.totalOfRows = response.results.meta.pagination.total;
        this.pageFrom = response.results.meta.pagination.current_page;
        this.pageTo = response.results.meta.pagination.count;
      }
    }
  }

  private countCharacters(text: string): number {
    return text.length;
  }

  private checkIfTheTagExists(tag: string): boolean {
    const tagExists = this.tagsList.some((objeto: any) => {
      if (objeto.name === tag) {
        return true;
      }
    });

    return tagExists;
  }

  /**
   * @param data
   * @private
   */
  private setItems(data: Array<any>): void {
    if (data.length) {
      const items = data.map((item: any) => {
        return { name: `Grupo ${item.groupId} - Código Fipe ${item.fipeCode}` };
      });
      this.setState({ items });
    }
  }

  private getItems(data: Array<ICatalogMap>): Array<string> {
    if (data.length) {
      return data.map((item: ICatalogMap) => {
        return `Grupo ${item.catalogToData?.catalogGroup} - Código Fipe ${item.catalogToData?.fipeCode}`;
      });
    }
    return [];
  }

  public removeItem(item: string) {
    const index = this.state.selectedItemNames.indexOf(item);
    if (index > -1) {
      this.state.selectedItemNames.splice(index, 1);
    }
  }

  private async getCatalogMap(fipeCode: string | null = null, catalogGroup: string | null = null): Promise<void> {
    try {
      this.setState({ loading: true });
      const response = await services.CatalogService.getCatalogMap(fipeCode, catalogGroup);
      if (response && response.results.data) {
        this.setState({ selectedItemNames: this.getItems(response.results.data), catalogMap: response.results.data });
      }
    } catch (e: any) {
      this.setState({ loading: false });
    } finally {
      this.setState({ loading: false });
    }
  }

  private async getAllCatalog(): Promise<void> {
    try {
      this.setState({ loading: true });
      const response = await services.CatalogService.getAllCatalog({ channel: 2 });
      this.setItems(response.data as Array<ICatalog>);
      this.setState({ allItems: response.data });
      this.getCatalogMap(this.fipe, this.grupo);
    } catch (e: any) {
      this.setState({ loading: false });
    } finally {
      this.setState({ loading: false });
    }
  }

  async onClickSaveConfig(): Promise<void> {
    try {
      this.setState({ loading: true });
      const payload: IStoreCatalogItem[] = [
        {
          catalogGroup: this.grupo,
          codeFrom: this.fipe,
          productId: this.carroProvisorio,
          catalogIdTo: this.findIds(this.state.selectedItemNames || [], this.state.allItems || []),
        },
      ];
      const response = await services.CatalogService.storeCatalogMap(payload);
      if (response.has_success) {
        this.text = response.message;
        this.snackbar = true;
        this.btn_disabled = true;
      } else {
        this.snackbar = true;
        this.text = messages.fail;
      }
      this.setState({ loading: false });
    } catch (e: any) {
      this.snackbar = true;
      this.text = messages.fail;
      this.setState({ loading: false });
    } finally {
      this.setState({ loading: false });
      this.getCatalogMap(this.fipe, this.grupo);
    }
  }

  /**
   * @param targetList
   * @param sourceList
   * @private
   */
  private findIds(targetList: string[], sourceList: ICatalog[]): number[] {
    const ids: number[] = [];
    targetList.forEach((item) => {
      const parts = item.split(" - ");
      const groupId = parts[0].replace("Grupo ", "");
      const fipeCode = parts[1].replace("Código Fipe ", "");
      sourceList.forEach((sourceItem) => {
        if (sourceItem.groupId === groupId && sourceItem.fipeCode === fipeCode) {
          ids.push(sourceItem.id);
        }
      });
    });
    return ids;
  }
}
</script>
<style lang="scss">
.btn-circle {
  min-width: 50px !important;
  min-height: 50px !important;
}

.input-color {
  height: 48px;
  min-height: 48px !important;
}

.money {
  color: var(--neutral-700);
  font-size: 1em;
  width: 100%;
  padding: 15px 15px 15px 15px;
  background-color: var(--neutral-000);
  border: 2px solid var(--neutral-500);

  &:target,
  &:focus-within,
  &:focus,
  &:active,
  &:hover {
    color: var(--movida-orange);
    border: 2px solid var(--neutral-700);
  }

  &:focus-visible {
    border: none;
    outline: 2px solid var(--neutral-200);
  }

  &:hover:focus-visible {
    border: none;
    outline: 2px solid var(--movida-orange);
  }
}
.tabs-config {
  width: 80%;
  padding-left: 1em;
  border-left: 1px solid var(--neutral-150);
}
</style>
