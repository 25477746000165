<template>
  <v-row>
    <v-col cols="12">
      <h1 class="title">Espera</h1>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col>
          <v-tabs v-model="tab" @change="onTabChange">
            <v-tab value="schedule">Agendamentos</v-tab>
            <v-tab value="deadlines">Prazo de Retirada</v-tab>
          </v-tabs>
          <v-divider />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-window v-model="tab">
            <v-window-item value="schedule">
              <ScheduleIndexView />
            </v-window-item>
            <v-window-item value="deadlines">
              <DeadlineIndexView />
            </v-window-item>
          </v-window>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import ScheduleIndexView from "@/views/schedule/index.vue";
import DeadlineIndexView from "@/views/deadlines/index.vue";

@Options({
  components: {
    ScheduleIndexView,
    DeadlineIndexView,
  },
})
export default class LeadTimeIndexView extends Vue {
  tab = "schedule";

  mounted(): void {
    this.updateTabFromHash();
    window.addEventListener("hashchange", this.updateTabFromHash);
  }

  beforeDestroy() {
    window.removeEventListener("hashchange", this.updateTabFromHash);
  }

  updateTabFromHash(): void {
    const hash = window.location.hash.replace("#", "");
    if (hash === "deadlines" || hash === "schedule") {
      this.tab = hash;
    } else {
      this.tab = "schedule";
    }
  }

  onTabChange(tab: string): void {
    window.location.hash = `#${tab}`;
  }
}
</script>
<style scoped lang="scss"></style>
