<template>
  <v-app id="inspire">
    <NavbarLeft :modules="modules" v-model="selectedModule" />
    <v-main>
      <NavHeader class="px-6 mt-2" :routes="functionalities" :right-menu="rightMenu" :user="user" />
      <div class="px-12 mt-7">
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import NavbarLeft from "@/components/Layout/NavbarLeft.vue";
import NavHeader from "@/components/Layout/NavHeader.vue";
import { User } from "@/models/user/User";
import { getFromStorage } from "@/utils/localStorage";
import { Menu } from "@/models/menu/Menu";
import Funcionalities from "@/utils/Funcionalities";

@Options({
  components: { NavHeader, NavbarLeft },
})
export default class HomePage extends Vue {
  public user: Array<User> = [];
  public selectedModule = "";
  public modules = [];
  public functionalities: Array<Menu> = [];
  public rightMenu = [{ icon: "mdi-exit-to-app", title: "Sair", to: "/" }];

  mounted(): void {
    this.getDataUSer();
    this.getFuncionalities();
  }

  getDataUSer(): void {
    const dataUserFromStorage = getFromStorage("DATA_USER");
    if (dataUserFromStorage !== null) {
      this.user = JSON.parse(dataUserFromStorage);
    }
  }

  getFuncionalities(): void {
    const functionalities = new Funcionalities();
    functionalities.getFuncionalitiesMenu();
    this.functionalities = functionalities.items;
  }
}
</script>
<style lang="css">
.grecaptcha-badge {
  display: none;
}
</style>
