import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "text-decoration-none" }
const _hoisted_2 = { class: "neutral-700" }
const _hoisted_3 = { class: "neutral-700" }
const _hoisted_4 = { class: "neutral-700" }
const _hoisted_5 = { class: "neutral-700" }
const _hoisted_6 = { class: "neutral-500 font-weight-medium" }
const _hoisted_7 = { class: "neutral-700" }
const _hoisted_8 = { class: "text-sm-body-2" }
const _hoisted_9 = { class: "text-sm-body-2" }
const _hoisted_10 = { class: "text-center mt-2 mb-3" }
const _hoisted_11 = { class: "neutral-300 mt-1 pb-1 font-regular" }
const _hoisted_12 = { class: "d-flex flex-no-wrap" }
const _hoisted_13 = { class: "v-col-8" }
const _hoisted_14 = { class: "v-col-4 offset-2" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { class: "d-flex" }
const _hoisted_17 = { class: "v-col-8" }
const _hoisted_18 = { class: "v-col-4 offset-2" }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { class: "d-flex justify-start mt-4" }
const _hoisted_21 = { class: "v-col-8" }
const _hoisted_22 = { class: "d-flex flex-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_v_pagination = _resolveComponent("v-pagination")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_color_picker = _resolveComponent("v-color-picker")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_v_label = _resolveComponent("v-label")!
  const _component_money3 = _resolveComponent("money3")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_table = _resolveComponent("v-table")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_window = _resolveComponent("v-window")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_card, {
      class: "mb-5",
      variant: 'flat'
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { class: "mb-0" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => _cache[32] || (_cache[32] = [
                _createElementVNode("p", { class: "neutral-500 mb-n2" }, "Catálogo", -1),
                _createElementVNode("h2", { class: "title" }, "Configurar item", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_v_col, { class: "d-flex justify-end" }, {
              default: _withCtx(() => [
                _createElementVNode("a", _hoisted_1, [
                  _createVNode(_component_v_btn, {
                    class: "rounded-lg bg-neutral-100 neutral-700",
                    size: "large",
                    variant: 'flat',
                    href: "/catalog"
                  }, {
                    default: _withCtx(() => _cache[33] || (_cache[33] = [
                      _createTextVNode(" Voltar ")
                    ])),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { cols: "12" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, {
              class: "rounded-lg",
              border: true,
              variant: 'flat'
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { "no-gutters": "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "3" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, {
                          class: "fill-height",
                          "no-gutters": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { cols: "2" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_avatar, {
                                  class: "ma-2",
                                  rounded: "0",
                                  size: "190"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_img, { src: "https://storage.googleapis.com/storage-vetor-zkm-dev/midias/zerokm/dev/vehicles-fipe/001558-0-image.webp" })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, { cols: "9" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, {
                          class: "fill-height",
                          "no-gutters": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { cols: "10" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_container, { fluid: "" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_row, { "no-gutters": "" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, {
                                          class: "d-flex flex-column mt-4",
                                          cols: "12"
                                        }, {
                                          default: _withCtx(() => [
                                            _cache[34] || (_cache[34] = _createElementVNode("small", { class: "neutral-500 font-weight-medium" }, "Descritivo", -1)),
                                            _createElementVNode("strong", _hoisted_2, _toDisplayString(this.descritivo), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_v_row, { "no-gutters": "" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_col, { cols: "12" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_container, { fluid: "" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_row, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_col, {
                                                  class: "d-flex flex-column",
                                                  cols: "2"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _cache[35] || (_cache[35] = _createElementVNode("small", { class: "neutral-500 font-weight-medium" }, "Grupo", -1)),
                                                    _createElementVNode("strong", _hoisted_3, _toDisplayString(this.grupo), 1)
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_col, {
                                                  class: "d-flex flex-column",
                                                  cols: "2"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _cache[36] || (_cache[36] = _createElementVNode("small", { class: "neutral-500 font-weight-medium" }, "FIPE", -1)),
                                                    _createElementVNode("strong", _hoisted_4, _toDisplayString(this.fipe), 1)
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_col, {
                                                  class: "d-flex flex-column",
                                                  cols: "4"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _cache[37] || (_cache[37] = _createElementVNode("small", { class: "neutral-500 font-weight-medium" }, "Modelo", -1)),
                                                    _createElementVNode("strong", _hoisted_5, _toDisplayString(this.modelo), 1)
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_col, {
                                                  class: "d-flex flex-column",
                                                  cols: "2"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("small", _hoisted_6, [
                                                      _createElementVNode("span", {
                                                        class: _normalizeClass([{
                                'bg-red': _ctx.allShowCases <= 0,
                                'bg-green': _ctx.allShowCases > 0,
                              }, "w-3 h-3 rounded-circle d-inline-block mr-1"])
                                                      }, null, 2),
                                                      _cache[38] || (_cache[38] = _createTextVNode(" Ativos "))
                                                    ]),
                                                    _createElementVNode("strong", _hoisted_7, _toDisplayString(_ctx.allShowCases), 1)
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_tabs, {
      modelValue: _ctx.activeTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event)),
      class: "mt-4 neutral-700"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_tab, { value: "disponibilidade" }, {
          default: _withCtx(() => _cache[39] || (_cache[39] = [
            _createTextVNode("Disponibilidade")
          ])),
          _: 1
        }),
        _createVNode(_component_v_tab, { value: "caracteristicas" }, {
          default: _withCtx(() => _cache[40] || (_cache[40] = [
            _createTextVNode("Características")
          ])),
          _: 1
        }),
        _createVNode(_component_v_tab, { value: "precos" }, {
          default: _withCtx(() => _cache[41] || (_cache[41] = [
            _createTextVNode("Preços")
          ])),
          _: 1
        }),
        _createVNode(_component_v_tab, { value: "configuracoes-basicas" }, {
          default: _withCtx(() => _cache[42] || (_cache[42] = [
            _createTextVNode("Configurações Básicas")
          ])),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_divider),
    _createVNode(_component_v_window, {
      modelValue: _ctx.activeTab,
      "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.activeTab) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_window_item, { value: "disponibilidade" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, {
              justify: "space-between",
              class: "mb-3 mt-2"
            }, {
              default: _withCtx(() => [
                (_ctx.editAll)
                  ? (_openBlock(), _createBlock(_component_v_col, { key: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_btn, {
                              class: "rounded-lg neutral-500 border-neutral-200",
                              size: "large",
                              variant: "outlined",
                              "prepend-icon": "mdi-filter-variant",
                              onClick: _ctx.compose
                            }, {
                              default: _withCtx(() => _cache[43] || (_cache[43] = [
                                _createTextVNode(" Editar ")
                              ])),
                              _: 1
                            }, 8, ["onClick"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (!_ctx.editAll)
                  ? (_openBlock(), _createBlock(_component_v_col, {
                      key: 1,
                      md: "2",
                      sm: "2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              modelValue: _ctx.needleSearch,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.needleSearch) = $event)),
                              class: "rounded-lg border-neutral-200 input-select",
                              variant: "outlined",
                              clearable: "",
                              density: "compact",
                              "hide-details": "",
                              label: "Buscar...",
                              "prepend-inner-icon": "mdi-magnify",
                              "single-line": "",
                              onInput: _ctx.searchByNeedle,
                              "onClick:clear": _ctx.clearFilter
                            }, null, 8, ["modelValue", "onInput", "onClick:clear"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_v_col, {
                  cols: "6",
                  class: "d-flex justify-end"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      class: "bg-red rounded-lg",
                      style: { marginTop: '14px' },
                      elevation: "0",
                      onClick: _ctx.clearCacheSubChannel
                    }, {
                      default: _withCtx(() => _cache[44] || (_cache[44] = [
                        _createTextVNode(" Limpar cache ")
                      ])),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  cols: "4",
                  class: "text-right d-flex justify-end"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_select, {
                          class: "select",
                          modelValue: _ctx.selectedRegions,
                          "onUpdate:modelValue": [
                            _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedRegions) = $event)),
                            _ctx.changeRegionSelected
                          ],
                          items: _ctx.regions,
                          "item-title": "Regiao",
                          "item-value": "RegiaoID",
                          label: "Região",
                          "persistent-hint": "",
                          "single-line": "",
                          multiple: "",
                          chips: "",
                          variant: "outlined",
                          "hide-details": "",
                          density: "compact",
                          color: 'movida-orange',
                          "no-data-text": "Carregando regiões..."
                        }, null, 8, ["modelValue", "items", "onUpdate:modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_select, {
                          modelValue: _ctx.selectedOrder,
                          "onUpdate:modelValue": [
                            _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedOrder) = $event)),
                            _ctx.changeOrderSelected
                          ],
                          items: _ctx.headersOrdenar,
                          "item-title": "title",
                          "item-value": "key",
                          label: "Ordenar lista",
                          "persistent-hint": "",
                          "single-line": "",
                          variant: "outlined",
                          "hide-details": "",
                          density: "compact",
                          color: 'movida-orange'
                        }, null, 8, ["modelValue", "items", "onUpdate:modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_data_table_server, {
              modelValue: _ctx.selected,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selected) = $event)),
              headers: _ctx.headers,
              items: _ctx.filiais,
              class: "rounded-lg neutral-500",
              loading: _ctx.isLoadingTable,
              "hide-default-footer": "",
              "fixed-header": "",
              "item-value": "name",
              "show-select": "",
              onChange: _ctx.changeRoute
            }, {
              [`item.name`]: _withCtx(({ item }) => [
                _createElementVNode("div", _hoisted_8, _toDisplayString(item.value.Loja), 1)
              ]),
              [`item.showCases`]: _withCtx(({ item }) => [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("span", {
                    class: _normalizeClass([{
                'bg-red': !item.value.showCases || (item.value.showCases.is_enabled !== '1' && item.value.showCases.is_enabled !== 'true'),
                'bg-green': item.value.showCases && (item.value.showCases.is_enabled === '1' || item.value.showCases.is_enabled === 'true'),
              }, "w-3 h-3 rounded-circle d-inline-block mr-2"])
                  }, null, 2),
                  _createTextVNode(" " + _toDisplayString(item.value.showCases && (item.value.showCases.is_enabled === "1" || item.value.showCases.is_enabled === "true") ? "Ativo" : item.value.showCases && item.value.showCases.is_enabled === "0" ? "Inativo" : "Inativo"), 1)
                ])
              ]),
              [`item.actions`]: _withCtx(({ item }) => [
                _createVNode(_component_v_btn, {
                  class: "rounded-lg neutral-500",
                  border: "neutral-200",
                  variant: 'outlined',
                  onClick: ($event: any) => (_ctx.composeWithItem(item.value))
                }, {
                  default: _withCtx(() => _cache[45] || (_cache[45] = [
                    _createTextVNode("Editar")
                  ])),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              bottom: _withCtx(() => _cache[46] || (_cache[46] = [])),
              _: 2
            }, 1032, ["modelValue", "headers", "items", "loading", "onChange"]),
            (!_ctx.showCases)
              ? (_openBlock(), _createBlock(_component_v_row, {
                  key: 0,
                  class: "mt-2 d-flex align-center",
                  justify: "center"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_component_v_pagination, {
                        modelValue: _ctx.currentPage,
                        "onUpdate:modelValue": [
                          _cache[5] || (_cache[5] = ($event: any) => ((_ctx.currentPage) = $event)),
                          _ctx.loadPage
                        ],
                        length: _ctx.numberOfPages,
                        "total-visible": 7,
                        onNext: _ctx.nextPage,
                        onPrev: _ctx.prevPage
                      }, null, 8, ["modelValue", "length", "onNext", "onPrev", "onUpdate:modelValue"]),
                      _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.pageFrom) + " a " + _toDisplayString(_ctx.pageTo) + " de " + _toDisplayString(_ctx.totalOfRows) + " resultados", 1)
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_v_dialog, {
              modelValue: _ctx.dialogCompose,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.dialogCompose) = $event)),
              width: "608"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, { class: "py-2" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_title, {
                      class: "font-weight-bold ml-4 py-2",
                      "primary-title": ""
                    }, {
                      default: _withCtx(() => _cache[47] || (_cache[47] = [
                        _createTextVNode(" Editar")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_divider, { class: "mx-5" }),
                    _createVNode(_component_v_card_text, { class: "mt-n3" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_form, {
                          ref: "sendForm",
                          modelValue: _ctx.valid,
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.valid) = $event)),
                          "lazy-validation": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, { cols: "12" }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_12, [
                                      _createElementVNode("div", _hoisted_13, [
                                        _createVNode(_component_v_card_title, { class: "neutral-700 font-weight-bold text-body-1" }, {
                                          default: _withCtx(() => _cache[48] || (_cache[48] = [
                                            _createTextVNode("Encomendas")
                                          ])),
                                          _: 1
                                        }),
                                        (_ctx.isEnabled)
                                          ? (_openBlock(), _createBlock(_component_v_card_subtitle, {
                                              key: 0,
                                              class: "font-weight-medium neutral-500 mt-n3"
                                            }, {
                                              default: _withCtx(() => _cache[49] || (_cache[49] = [
                                                _createTextVNode("Ativado")
                                              ])),
                                              _: 1
                                            }))
                                          : _createCommentVNode("", true),
                                        (!_ctx.isEnabled)
                                          ? (_openBlock(), _createBlock(_component_v_card_subtitle, {
                                              key: 1,
                                              class: "font-weight-medium neutral-500 mt-n3"
                                            }, {
                                              default: _withCtx(() => _cache[50] || (_cache[50] = [
                                                _createTextVNode("Desativado")
                                              ])),
                                              _: 1
                                            }))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _createElementVNode("div", _hoisted_14, [
                                        _createVNode(_component_v_switch, {
                                          modelValue: _ctx.isEnabled,
                                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.isEnabled) = $event)),
                                          color: "#FF5000",
                                          inset: ""
                                        }, null, 8, ["modelValue"])
                                      ])
                                    ])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            (_ctx.isEnabled)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                  _createVNode(_component_v_card, {
                                    class: "border-neutral-200 rounded-lg pb-3",
                                    elevation: "0",
                                    theme: "white"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_16, [
                                        _createElementVNode("div", _hoisted_17, [
                                          _createVNode(_component_v_card_title, { class: "neutral-700 font-weight-bold text-body-1" }, {
                                            default: _withCtx(() => _cache[51] || (_cache[51] = [
                                              _createTextVNode("Cores sob encomenda")
                                            ])),
                                            _: 1
                                          }),
                                          (_ctx.isEnabledColor)
                                            ? (_openBlock(), _createBlock(_component_v_card_subtitle, {
                                                key: 0,
                                                class: "font-weight-medium neutral-500 mt-n3"
                                              }, {
                                                default: _withCtx(() => _cache[52] || (_cache[52] = [
                                                  _createTextVNode("Personalizado")
                                                ])),
                                                _: 1
                                              }))
                                            : _createCommentVNode("", true),
                                          (!_ctx.isEnabledColor)
                                            ? (_openBlock(), _createBlock(_component_v_card_subtitle, {
                                                key: 1,
                                                class: "font-weight-medium neutral-500 mt-n3"
                                              }, {
                                                default: _withCtx(() => _cache[53] || (_cache[53] = [
                                                  _createTextVNode("Padrão (Vetor)")
                                                ])),
                                                _: 1
                                              }))
                                            : _createCommentVNode("", true)
                                        ]),
                                        _createElementVNode("div", _hoisted_18, [
                                          _createVNode(_component_v_switch, {
                                            class: "mt-2",
                                            modelValue: _ctx.isEnabledColor,
                                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.isEnabledColor) = $event)),
                                            color: "#FF5000",
                                            inset: ""
                                          }, null, 8, ["modelValue"])
                                        ])
                                      ]),
                                      (_ctx.isEnabledColor)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                            _createVNode(_component_v_divider, { class: "mx-3 mb-5" }),
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colors, (colorId, index) => {
                                              return (_openBlock(), _createBlock(_component_v_row, {
                                                key: index,
                                                class: "space-between"
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_col, {
                                                    class: "d-flex justify-center",
                                                    cols: "2"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_v_btn, {
                                                        color: _ctx.getColor(index),
                                                        class: "rounded-circle d-inline-block btn-circle ml-5",
                                                        onClick: ($event: any) => (_ctx.valueIndex(index))
                                                      }, null, 8, ["color", "onClick"])
                                                    ]),
                                                    _: 2
                                                  }, 1024),
                                                  _createVNode(_component_v_col, {
                                                    cols: "10",
                                                    class: "d-flex align-center"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_v_text_field, {
                                                        modelValue: _ctx.colorsName[index],
                                                        "onUpdate:modelValue": ($event: any) => ((_ctx.colorsName[index]) = $event),
                                                        class: "rounded-lg input-color",
                                                        "persistent-hint": "",
                                                        variant: "outlined"
                                                      }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                                                      _createVNode(_component_v_btn, {
                                                        size: "large",
                                                        class: "neutral-500 align-self-center mt-2",
                                                        variant: "text",
                                                        onClick: ($event: any) => (_ctx.removeColor(index))
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_v_icon, {
                                                            size: "x-large",
                                                            icon: "mdi-delete-outline"
                                                          })
                                                        ]),
                                                        _: 2
                                                      }, 1032, ["onClick"])
                                                    ]),
                                                    _: 2
                                                  }, 1024),
                                                  _createVNode(_component_v_col, { cols: "2" })
                                                ]),
                                                _: 2
                                              }, 1024))
                                            }), 128)),
                                            _createElementVNode("div", _hoisted_20, [
                                              _createElementVNode("div", _hoisted_21, [
                                                _createVNode(_component_v_btn, {
                                                  size: "small",
                                                  class: "neutral-500",
                                                  variant: "text",
                                                  onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.addColor('#FFFFFF')))
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_icon, { icon: "mdi-plus-circle-outline mr-2" }),
                                                    _cache[54] || (_cache[54] = _createTextVNode(" Adicionar cor "))
                                                  ]),
                                                  _: 1
                                                })
                                              ])
                                            ])
                                          ]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_row, { class: "mt-4" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_col, {
                                        cols: "8",
                                        class: "d-flex align-center align-stretch"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_card_title, { class: "neutral-700 font-weight-bold text-body-1 align-self-center mt-n3" }, {
                                            default: _withCtx(() => _cache[55] || (_cache[55] = [
                                              _createTextVNode("Prazo para encomendas")
                                            ])),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_col, {
                                        cols: "4",
                                        class: "d-flex justify-center align-stretch mb-5"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_select, {
                                            modelValue: _ctx.delivery_time,
                                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.delivery_time) = $event)),
                                            items: _ctx.items,
                                            "persistent-hint": "",
                                            variant: "outlined",
                                            class: "rounded-lg neutral-500",
                                            density: "compact",
                                            "hide-details": "",
                                            color: 'movida-orange',
                                            "no-data-text": "Carregando prazos..."
                                          }, null, 8, ["modelValue", "items"])
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]))
                              : _createCommentVNode("", true),
                            _createVNode(_component_v_divider),
                            _createVNode(_component_v_row, { class: "mt-2" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, { class: "d-flex justify-end" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_btn, {
                                      class: "mr-2 border-neutral-200 neutral-500 rounded-lg",
                                      size: "large",
                                      onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.cancel())),
                                      variant: "outlined"
                                    }, {
                                      default: _withCtx(() => _cache[56] || (_cache[56] = [
                                        _createTextVNode(" Cancelar")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_btn, {
                                      class: "bg-movida-orange rounded-lg",
                                      elevation: "0",
                                      size: "large",
                                      onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.saveCatalog()))
                                    }, {
                                      default: _withCtx(() => _cache[57] || (_cache[57] = [
                                        _createTextVNode(" Salvar")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_v_dialog, {
              modelValue: _ctx.dialogColor,
              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.dialogColor) = $event)),
              width: "auto"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_color_picker, {
                      modelValue: _ctx.selectedColor,
                      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.selectedColor) = $event)),
                      mode: "hex"
                    }, null, 8, ["modelValue"]),
                    _createVNode(_component_v_card_actions, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          class: "bg-movida-orange rounded-lg my-2",
                          elevation: "0",
                          onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.saveColor())),
                          block: ""
                        }, {
                          default: _withCtx(() => _cache[58] || (_cache[58] = [
                            _createTextVNode("Confirmar")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_v_snackbar, {
              modelValue: _ctx.snackbar,
              "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.snackbar) = $event)),
              timeout: _ctx.timeout
            }, {
              actions: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  variant: "text",
                  onClick: _ctx.closeSnack
                }, {
                  default: _withCtx(() => _cache[60] || (_cache[60] = [
                    _createTextVNode(" Fechar")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_v_icon, { class: "ml-1" }, {
                  default: _withCtx(() => _cache[59] || (_cache[59] = [
                    _createTextVNode(" mdi-information-outline")
                  ])),
                  _: 1
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.text) + " ", 1)
              ]),
              _: 1
            }, 8, ["modelValue", "timeout"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_window_item, {
          class: "mt-3",
          value: "precos"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_container, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, {
                  align: "center",
                  justify: "space-between"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "5"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_label, {
                                  class: "label-title",
                                  for: "price_starting"
                                }, {
                                  default: _withCtx(() => _cache[61] || (_cache[61] = [
                                    _createTextVNode("A partir de:")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_component_money3, _mergeProps({
                                  class: "money rounded-lg",
                                  modelValue: _ctx.price_starting,
                                  "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.price_starting) = $event))
                                }, _ctx.money), null, 16, ["modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_label, {
                                  class: "label-title",
                                  for: "price_promotional"
                                }, {
                                  default: _withCtx(() => _cache[62] || (_cache[62] = [
                                    _createTextVNode("Valor Promocional:")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_component_money3, _mergeProps({
                                  class: "money rounded-lg",
                                  modelValue: _ctx.price_promotional,
                                  "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.price_promotional) = $event))
                                }, _ctx.money), null, 16, ["modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_btn, {
                                  disabled: !_ctx.isPriceStartingFilled,
                                  class: "bg-movida-orange rounded-lg",
                                  elevation: "0",
                                  onClick: _ctx.catalogPricingStore
                                }, {
                                  default: _withCtx(() => _cache[63] || (_cache[63] = [
                                    _createTextVNode("Salvar")
                                  ])),
                                  _: 1
                                }, 8, ["disabled", "onClick"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          class: "bg-red rounded-lg",
                          elevation: "0",
                          onClick: _ctx.clearPriceCacheSync
                        }, {
                          default: _withCtx(() => _cache[64] || (_cache[64] = [
                            _createTextVNode(" Limpar cache ")
                          ])),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_window_item, {
          class: "mt-3",
          value: "caracteristicas"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_container, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, { style: { boxShadow: 'none', width: '30%' } }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card_title, null, {
                          default: _withCtx(() => _cache[65] || (_cache[65] = [
                            _createTextVNode("Tags")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_card_subtitle, null, {
                          default: _withCtx(() => _cache[66] || (_cache[66] = [
                            _createTextVNode("Crie novas tags")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { class: "col-4 align-center" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, { cols: "8" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_text_field, {
                                      modelValue: _ctx.nameCatalogTag,
                                      "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.nameCatalogTag) = $event)),
                                      clearable: "",
                                      color: "#F47920",
                                      "hide-details": "auto",
                                      label: "Nova Tag",
                                      variant: "outlined"
                                    }, null, 8, ["modelValue"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_btn, {
                              disabled: _ctx.btn_disabled,
                              class: "bg-movida-orange rounded-lg mt-4",
                              elevation: "0",
                              onClick: _ctx.validateTag
                            }, {
                              default: _withCtx(() => _cache[67] || (_cache[67] = [
                                _createTextVNode("Criar tag")
                              ])),
                              _: 1
                            }, 8, ["disabled", "onClick"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_card, { style: { boxShadow: 'none', width: '50%' } }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card_title, null, {
                          default: _withCtx(() => _cache[68] || (_cache[68] = [
                            _createTextVNode("Grupo de tags")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_card_subtitle, null, {
                          default: _withCtx(() => _cache[69] || (_cache[69] = [
                            _createTextVNode("Crie novos grupos de tags")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { class: "col-6 align-center" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, { cols: "6" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_select, {
                                      modelValue: _ctx.selectedTags,
                                      "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.selectedTags) = $event)),
                                      items: _ctx.tagsList,
                                      chips: "",
                                      color: "#F47920",
                                      "item-title": "name",
                                      "item-value": "id",
                                      label: "Tags",
                                      multiple: ""
                                    }, null, 8, ["modelValue", "items"])
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_v_col, { cols: "6" }, {
                                  default: _withCtx(() => [
                                    (_ctx.showFieldIsEnabledTagsGroup)
                                      ? (_openBlock(), _createBlock(_component_v_switch, {
                                          key: 0,
                                          modelValue: _ctx.isEnabledGroupTags,
                                          "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.isEnabledGroupTags) = $event)),
                                          color: "#FF5000",
                                          inset: "",
                                          label: "Ativado",
                                          onClick: _ctx.disabledAndEnabledGroupTags
                                        }, null, 8, ["modelValue", "onClick"]))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_btn, {
                              disabled: _ctx.btn_disabled,
                              class: "bg-movida-orange rounded-lg",
                              elevation: "0",
                              onClick: _cache[23] || (_cache[23] = ($event: any) => (_ctx.groupTagsStore()))
                            }, {
                              default: _withCtx(() => _cache[70] || (_cache[70] = [
                                _createTextVNode(" Salvar grupo")
                              ])),
                              _: 1
                            }, 8, ["disabled"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_window_item, {
          class: "mt-3",
          value: "configuracoes-basicas"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_container, { class: "ma-0 pa-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_22, [
                      _createVNode(_component_v_tabs, {
                        modelValue: _ctx.tabConfigs,
                        "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.tabConfigs) = $event)),
                        direction: "vertical",
                        class: "neutral-700"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_tab, {
                            text: "Fipe Correspondente",
                            value: "fip-correspondente"
                          }, {
                            default: _withCtx(() => _cache[71] || (_cache[71] = [
                              _createTextVNode("Carro Provisório")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_tab, {
                            text: "Plataforma",
                            value: "plataforma"
                          }, {
                            default: _withCtx(() => _cache[72] || (_cache[72] = [
                              _createTextVNode("Plataforma")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue"]),
                      _createVNode(_component_v_window, {
                        modelValue: _ctx.tabConfigs,
                        "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.tabConfigs) = $event)),
                        class: "tabs-config"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_window_item, { value: "fip-correspondente" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_row, {
                                align: "center",
                                justify: "space-between"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_card, {
                                    flat: "",
                                    style: { boxShadow: 'none', width: '100%', padding: '24px' }
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_card_title, null, {
                                        default: _withCtx(() => _cache[73] || (_cache[73] = [
                                          _createTextVNode("Grupos RAC correspondentes")
                                        ])),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_card_subtitle, null, {
                                        default: _withCtx(() => _cache[74] || (_cache[74] = [
                                          _createTextVNode(" Selecione o veículo correspondente do grupo RAC para reservas do Carro Provisório ")
                                        ])),
                                        _: 1
                                      }),
                                      (!_ctx.state.loading && _ctx.state?.allItems?.length)
                                        ? (_openBlock(), _createBlock(_component_v_row, {
                                            key: 0,
                                            class: "mt-4"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_col, null, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_select, {
                                                    modelValue: _ctx.state.selectedItemNames,
                                                    "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.state.selectedItemNames) = $event)),
                                                    items: _ctx.itemNames,
                                                    label: "Selecionar Itens",
                                                    multiple: "",
                                                    chips: "",
                                                    "menu-props": { closeOnContentClick: false },
                                                    ref: "selectInput"
                                                  }, {
                                                    selection: _withCtx((data) => [
                                                      (_openBlock(), _createBlock(_component_v_chip, {
                                                        key: data.item,
                                                        close: "",
                                                        "onClick:close": ($event: any) => (_ctx.removeItem(data.item))
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString(data.item), 1)
                                                        ]),
                                                        _: 2
                                                      }, 1032, ["onClick:close"]))
                                                    ]),
                                                    _: 1
                                                  }, 8, ["modelValue", "items"])
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          }))
                                        : _createCommentVNode("", true),
                                      _createVNode(_component_v_col, {
                                        cols: "12",
                                        md: "12",
                                        class: "mb-6"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_btn, {
                                            disabled: _ctx.state.loading,
                                            class: "bg-movida-orange rounded-lg float-right mt-2",
                                            elevation: "0",
                                            onClick: _cache[26] || (_cache[26] = ($event: any) => (_ctx.onClickSaveConfig()))
                                          }, {
                                            default: _withCtx(() => _cache[75] || (_cache[75] = [
                                              _createTextVNode("Salvar")
                                            ])),
                                            _: 1
                                          }, 8, ["disabled"]),
                                          _createVNode(_component_v_divider)
                                        ]),
                                        _: 1
                                      }),
                                      (!_ctx.state.loading && _ctx.state?.allItems?.length && _ctx.state?.catalogMap?.length > 0)
                                        ? (_openBlock(), _createBlock(_component_v_card_subtitle, { key: 1 }, {
                                            default: _withCtx(() => _cache[76] || (_cache[76] = [
                                              _createTextVNode(" Itens já estão vinculados ")
                                            ])),
                                            _: 1
                                          }))
                                        : _createCommentVNode("", true),
                                      (!_ctx.state.loading && _ctx.state?.allItems?.length && _ctx.state?.catalogMap?.length > 0)
                                        ? (_openBlock(), _createBlock(_component_v_table, {
                                            key: 2,
                                            theme: "white"
                                          }, {
                                            default: _withCtx(() => [
                                              _cache[77] || (_cache[77] = _createElementVNode("thead", null, [
                                                _createElementVNode("tr", null, [
                                                  _createElementVNode("th", { class: "text-left" }, " De "),
                                                  _createElementVNode("th", { class: "text-left" }, " Para ")
                                                ])
                                              ], -1)),
                                              _createElementVNode("tbody", null, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.catalogMap, (item) => {
                                                  return (_openBlock(), _createElementBlock("tr", {
                                                    key: item.createdAt
                                                  }, [
                                                    _createElementVNode("td", null, "Grupo: " + _toDisplayString(item?.catalogFromData?.catalogGroup) + " | Código FIPE - " + _toDisplayString(item?.catalogFromData?.fipeCode), 1),
                                                    _createElementVNode("td", null, "Grupo: " + _toDisplayString(item?.catalogToData?.catalogGroup) + " | Código FIPE - " + _toDisplayString(item?.catalogToData?.fipeCode), 1)
                                                  ]))
                                                }), 128))
                                              ])
                                            ]),
                                            _: 1
                                          }))
                                        : _createCommentVNode("", true),
                                      (!_ctx.state.loading && !_ctx.state?.allItems?.length)
                                        ? (_openBlock(), _createBlock(_component_v_row, { key: 3 }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_sheet, { class: "d-flex align-center justify-center pa-8" }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_chip, null, {
                                                    default: _withCtx(() => _cache[78] || (_cache[78] = [
                                                      _createTextVNode(" Nenhum item disponível ")
                                                    ])),
                                                    _: 1
                                                  })
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          }))
                                        : _createCommentVNode("", true),
                                      (_ctx.state.loading)
                                        ? (_openBlock(), _createBlock(_component_v_row, { key: 4 }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_sheet, { class: "d-flex align-center justify-center pa-8" }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_chip, null, {
                                                    default: _withCtx(() => _cache[79] || (_cache[79] = [
                                                      _createTextVNode(" Carregando... ")
                                                    ])),
                                                    _: 1
                                                  })
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          }))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_window_item, { value: "plataforma" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_container, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_row, {
                                    align: "center",
                                    justify: "space-between"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_card, { style: { boxShadow: 'none', width: '100%' } }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_card_title, null, {
                                            default: _withCtx(() => _cache[80] || (_cache[80] = [
                                              _createTextVNode("Subcanal")
                                            ])),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_card_subtitle, null, {
                                            default: _withCtx(() => _cache[81] || (_cache[81] = [
                                              _createTextVNode("Selecionar subcanal")
                                            ])),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_row, null, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_col, { cols: "12" }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_select, {
                                                    modelValue: _ctx.selectedSubchannels,
                                                    "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.selectedSubchannels) = $event)),
                                                    items: _ctx.subchannelsList,
                                                    chips: "",
                                                    color: "#F47920",
                                                    "item-title": "name",
                                                    "item-value": "id",
                                                    label: "Subcanais",
                                                    multiple: ""
                                                  }, null, 8, ["modelValue", "items"])
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_col, {
                                        cols: "12",
                                        md: "12"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_divider),
                                          _createVNode(_component_v_btn, {
                                            disabled: _ctx.btn_disabled,
                                            class: "bg-movida-orange rounded-lg float-right mt-2",
                                            elevation: "0",
                                            onClick: _cache[28] || (_cache[28] = ($event: any) => (_ctx.subchannelsStore()))
                                          }, {
                                            default: _withCtx(() => _cache[82] || (_cache[82] = [
                                              _createTextVNode(" Salvar")
                                            ])),
                                            _: 1
                                          }, 8, ["disabled"]),
                                          _createVNode(_component_v_btn, {
                                            class: "bg-red rounded-lg float-right mt-2 ml-2 mr-1",
                                            elevation: "0",
                                            onClick: _ctx.clearCacheSubChannel
                                          }, {
                                            default: _withCtx(() => _cache[83] || (_cache[83] = [
                                              _createTextVNode(" Limpar cache ")
                                            ])),
                                            _: 1
                                          }, 8, ["onClick"])
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_snackbar, {
      modelValue: _ctx.snackbar,
      "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.snackbar) = $event)),
      timeout: _ctx.timeout
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_v_btn, {
          variant: "text",
          onClick: _ctx.closeSnack
        }, {
          default: _withCtx(() => _cache[85] || (_cache[85] = [
            _createTextVNode(" Fechar ")
          ])),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_icon, { class: "ml-1" }, {
          default: _withCtx(() => _cache[84] || (_cache[84] = [
            _createTextVNode(" mdi-information-outline ")
          ])),
          _: 1
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.text) + " ", 1)
      ]),
      _: 1
    }, 8, ["modelValue", "timeout"])
  ], 64))
}