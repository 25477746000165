import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-space-between" }
const _hoisted_2 = { class: "text-decoration-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[8] || (_cache[8] = _createElementVNode("h1", { class: "" }, "Criar Permissão de Perfil", -1)),
      _createElementVNode("a", _hoisted_2, [
        _createVNode(_component_v_btn, {
          class: "text-white",
          color: "grey",
          href: "/profiles"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, null, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode(" mdi-plus ")
              ])),
              _: 1
            }),
            _cache[5] || (_cache[5] = _createTextVNode(" Voltar "))
          ]),
          _: 1
        }),
        _createVNode(_component_v_btn, {
          class: "text-white",
          color: "movida-orange",
          onClick: _ctx.store
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, null, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode(" mdi-plus ")
              ])),
              _: 1
            }),
            _cache[7] || (_cache[7] = _createTextVNode(" Salvar "))
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              md: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, {
                  label: "Nome",
                  rules: _ctx.rules,
                  "hide-details": "auto",
                  modelValue: _ctx.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
                  required: "true"
                }, null, 8, ["rules", "modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              md: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, {
                  label: "Descrição",
                  rules: _ctx.rules,
                  "hide-details": "auto",
                  modelValue: _ctx.description,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.description) = $event)),
                  required: "true"
                }, null, 8, ["rules", "modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_snackbar, {
      modelValue: _ctx.snackbar,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.snackbar) = $event)),
      timeout: _ctx.timeout
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_v_btn, {
          variant: "text",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.snackbar = false))
        }, {
          default: _withCtx(() => _cache[10] || (_cache[10] = [
            _createTextVNode(" Fechar ")
          ])),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_icon, { class: "ml-1" }, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [
            _createTextVNode(" mdi-information-outline ")
          ])),
          _: 1
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.text) + " ", 1)
      ]),
      _: 1
    }, 8, ["modelValue", "timeout"])
  ], 64))
}