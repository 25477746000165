<template>
  <v-form @submit.prevent="importCsv">
    <div class="d-flex justify-space-between">
      <h1>Importar CSV</h1>
      <div>
        <v-btn class="text-white mr-2" color="grey" @click="backToPreviousPage">Voltar</v-btn>
        <v-btn class="text-white" color="movida-orange" type="submit" :disabled="btn_disabled">Confirmar</v-btn>
      </div>
    </div>
    <v-file-input v-model="csvFile" accept=".csv" label="Escolha o arquivo CSV" outlined dense required></v-file-input>
    <v-progress-linear v-if="loading" indeterminate></v-progress-linear>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      <v-icon class="ml-1">mdi-information-outline</v-icon> {{ snackbarText }}
      <template v-slot:actions>
        <v-btn variant="text" @click="closeSnack">Fechar</v-btn>
      </template>
    </v-snackbar>

    <v-data-table v-if="results.length > 0" :items="results" :headers="headers" class="mt-4">
      <template v-slot:headers>
        <tr>
          <th v-for="header in headers" :key="header.value" class="text-left">{{ header.text }}</th>
        </tr>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.value.pedido_frota_id }}</td>
          <td>{{ item.value.agendamento_id }}</td>
          <td>{{ item.value.filial }}</td>
          <td>{{ item.value.message }}</td>
        </tr>
      </template>
    </v-data-table>
  </v-form>
</template>

<script lang="ts">
import Papa from "papaparse";
import { Options, Vue } from "vue-class-component";
import ScheduleService from "@/services/schedule/ScheduleService";
import { getFromStorage } from "@/utils/localStorage";

@Options({})
export default class ScheduleImportView extends Vue {
  public csvFile: FileList | null = null;
  public loading = false;
  public snackbar = false;
  public snackbarText = "";
  public btn_disabled = false;
  public results: any[] = [];
  public timeout = 10000;

  public headers = [
    { text: "Pedido ID", value: "pedido_frota_id" },
    { text: "Agendamento ID", value: "agendamento_id" },
    { text: "Filial", value: "filial" },
    { text: "Mensagem", value: "message" },
  ];

  async importCsv(): Promise<void> {
    if (!this.csvFile) {
      this.snackbarText = "Por favor, selecione um arquivo CSV.";
      this.snackbar = true;
      return;
    }

    const userData = getFromStorage("DATA_USER");
    if (!userData) {
      this.snackbarText = "Erro ao obter dados do usuário.";
      this.snackbar = true;
      return;
    }

    const parsedData = JSON.parse(userData);
    const userId = parsedData.id;

    this.loading = true;
    this.btn_disabled = true;

    try {
      const file = this.csvFile[0];
      const arrayBuffer = await file.arrayBuffer();
      const text = new TextDecoder("windows-1252").decode(arrayBuffer);
      const parsedCsv = Papa.parse(text, { header: false });

      (parsedCsv.data as any[]).forEach((row: any[]) => {
        row[3] = userId;
      });

      const modifiedCsv = Papa.unparse(parsedCsv.data);
      console.log(modifiedCsv);
      const blob = new Blob([modifiedCsv], { type: "text/csv" });
      const dataForm = new FormData();
      dataForm.append("csv_file", blob, file.name);

      const response = await ScheduleService.import(dataForm);

      if (response.has_success) {
        this.results = response.results.data.success.concat(response.results.data.errors);
        console.log(this.results);
        this.snackbarText = response.message;
      } else {
        this.snackbarText = "Erro na importação.";
      }
    } catch (error) {
      this.snackbarText = "Erro ao enviar o arquivo. Tente novamente.";
    } finally {
      this.loading = false;
      this.btn_disabled = false;
      this.snackbar = true;
    }
  }

  backToPreviousPage(): void {
    this.$router.go(-1);
  }

  closeSnack(): void {
    this.snackbar = false;
  }
}
</script>

<style lang="css">
.default-data-base {
  border: solid #efecec 0.4px;
}

.w-3 {
  width: 10px;
}

.h-3 {
  height: 10px;
}

.dp__input {
  box-shadow: 0px 3px 1px -2px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)), 0px 2px 2px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)), 0px 1px 5px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.12));
}

.datepickerForm {
  height: 59px;
}

.dp__action_select,
.dp__range_end,
.dp__range_start,
.dp__active_date {
  background-color: #ff9800 !important;
}
</style>
