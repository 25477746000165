<template>
  <div class="d-flex justify-space-between">
    <h1 class="">Criar Permissão de Perfil</h1>
    <a class="text-decoration-none">
      <v-btn class="text-white" color="grey" href="/profiles">
        <v-icon> mdi-plus </v-icon>
        Voltar
      </v-btn>
      <v-btn class="text-white" color="movida-orange" @click="store">
        <v-icon> mdi-plus </v-icon>
        Salvar
      </v-btn>
    </a>
  </div>
  <v-container>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field label="Nome" :rules="rules" hide-details="auto" v-model="name" required="true"></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field label="Descrição" :rules="rules" hide-details="auto" v-model="description" required="true"></v-text-field>
      </v-col>
    </v-row>
  </v-container>
  <v-snackbar v-model="snackbar" :timeout="timeout">
    <v-icon class="ml-1"> mdi-information-outline </v-icon> {{ text }}
    <template v-slot:actions>
      <v-btn variant="text" @click="snackbar = false"> Fechar </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import * as services from "@/services";
import { ProfileStoreRequest } from "@/models/profile/ProfileStoreRequest";

@Options({
  components: {},
})
export default class ProfileCreateView extends Vue {
  public rules = [(value: any) => !!value || "Required."];
  public name = "";
  public description = "";
  public snackbar = false;
  public text = "Alterações salvas com sucesso!";
  public timeout = 2000;
  async store(event: any): Promise<void> {
    const storeRequest: ProfileStoreRequest = {
      name: this.name,
      description: this.description,
    };
    const response = await services.ProfileService.store(storeRequest);
    this.snackbar = true;
  }
}
</script>
<style lang="css">
.default-data-base {
  border: solid #efecec 0.4px;
}

.w-3 {
  width: 10px;
}

.h-3 {
  height: 10px;
}
</style>
@/models/profile/ProfileStoreRequest
