import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "neutral-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _cache[0] || (_cache[0] = _createElementVNode("h1", { class: "pt-6 mb-4 title" }, "Início", -1)),
      _createElementVNode("p", _hoisted_2, "Bem-vindo ao ambiente " + _toDisplayString(_ctx.moduleName) + " do Gestão de Produtos", 1)
    ]),
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          md: "12",
          class: "mt-n1"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_list, {
              density: "compact",
              class: "navbar-home"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
                  return (_openBlock(), _createBlock(_component_v_list_item, {
                    key: i,
                    value: item,
                    href: item.href
                  }, {
                    prepend: _withCtx(() => [
                      _createVNode(_component_v_icon, {
                        icon: item.icon,
                        size: "large"
                      }, null, 8, ["icon"])
                    ]),
                    append: _withCtx(() => [
                      _createVNode(_component_v_icon, {
                        icon: "mdi-arrow-right",
                        size: "large",
                        class: "arrow"
                      })
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_title, {
                        textContent: _toDisplayString(item.text)
                      }, null, 8, ["textContent"]),
                      _createVNode(_component_v_list_item_subtitle, {
                        textContent: _toDisplayString(item.description)
                      }, null, 8, ["textContent"])
                    ]),
                    _: 2
                  }, 1032, ["value", "href"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}