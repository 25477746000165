import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"border":"1px solid #a0a0a0, background-color: #f0f0f0, height: 32px, width: 100px"} }
const _hoisted_2 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["content", { done: _ctx.task?.done }])
    }, _toDisplayString(_ctx.task?.content), 3),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDone && _ctx.toggleDone(...args)))
      }, _toDisplayString(_ctx.task?.done ? "Desfazer" : "Pronto"), 1),
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.removeTask && _ctx.removeTask(...args))),
        class: "delete"
      }, "Apagar")
    ]),
    _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1))
  ]))
}