import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "text-grey lighten-1" }
const _hoisted_2 = {
  key: 3,
  class: "pb-2 text-grey lighten-1"
}
const _hoisted_3 = { class: "position" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_v_select = _resolveComponent("v-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { justify: "space-between" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => [
                _cache[2] || (_cache[2] = _createElementVNode("h1", null, "Vitrine", -1)),
                _createElementVNode("h5", _hoisted_1, " Canal " + _toDisplayString(_ctx.state.cluster.sales_channel_id) + ", Mínimo " + _toDisplayString(_ctx.state.cluster.min) + ", Máximo " + _toDisplayString(_ctx.state.cluster.max), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, { cols: "auto" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  class: "text-white btn-cancel elevation-0",
                  color: "grey",
                  href: "/clusters",
                  disabled: _ctx.state.loading
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode("mdi-cancel")
                      ])),
                      _: 1
                    }),
                    _cache[4] || (_cache[4] = _createTextVNode(" Voltar "))
                  ]),
                  _: 1
                }, 8, ["disabled"]),
                _createVNode(_component_v_btn, {
                  color: "movida-orange",
                  class: "text-white elevation-0",
                  onClick: _ctx.toSave,
                  disabled: _ctx.state.loading || !_ctx.isFormValid
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode("mdi-plus")
                      ])),
                      _: 1
                    }),
                    _cache[6] || (_cache[6] = _createTextVNode(" Salvar "))
                  ]),
                  _: 1
                }, 8, ["onClick", "disabled"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_divider)
      ]),
      _: 1
    }),
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        (!_ctx.state.loading)
          ? (_openBlock(), _createBlock(_component_v_card_title, {
              key: 0,
              class: "pa-0"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.state.cluster.name), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.state.serviceError)
          ? (_openBlock(), _createBlock(_component_v_alert, {
              key: 1,
              type: "error",
              class: "mt-5 mb-5"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Ocorreu um erro ao carregar os dados - " + _toDisplayString(_ctx.state.text), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.state.loading && _ctx.state.text)
          ? (_openBlock(), _createBlock(_component_v_sheet, {
              key: 2,
              class: "d-flex align-center justify-center pt-3 pb-3"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_chip, {
                  color: _ctx.state.serviceError ? 'red' : ''
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.state.text), 1)
                  ]),
                  _: 1
                }, 8, ["color"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.filteredItems.length && !_ctx.state.loading)
          ? (_openBlock(), _createElementBlock("h5", _hoisted_2, "Arraste para alterar a posição dos grupos"))
          : _createCommentVNode("", true),
        (_ctx.filteredItems.length && !_ctx.state.loading)
          ? (_openBlock(), _createBlock(_component_v_row, { key: 4 }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      class: "text-white mb-5 mt-5 elevation-0 flex-md-row",
                      color: "grey",
                      onClick: _ctx.toClean,
                      disabled: _ctx.state.loading
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => _cache[7] || (_cache[7] = [
                            _createTextVNode("mdi-close-circle-outline")
                          ])),
                          _: 1
                        }),
                        _cache[8] || (_cache[8] = _createTextVNode(" Limpar Vitrine "))
                      ]),
                      _: 1
                    }, 8, ["onClick", "disabled"]),
                    _createVNode(_component_draggable, {
                      class: "dragArea list-group w-full",
                      modelValue: _ctx.filteredItems,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filteredItems) = $event)),
                      onEnd: _ctx.onDragEnd
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredItems, (element, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "list-group-item bg-gray-300 m-1 p-3 rounded-md text-center",
                            key: element.name
                          }, [
                            _createElementVNode("span", _hoisted_3, _toDisplayString(index + 1 + "º"), 1),
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => _cache[9] || (_cache[9] = [
                                _createTextVNode("mdi-drag")
                              ])),
                              _: 1
                            }),
                            _createTextVNode(" " + _toDisplayString(element.name), 1)
                          ]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue", "onEnd"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        (!_ctx.state.loading)
          ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_select, {
                      modelValue: _ctx.state.selectedItemNames,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.selectedItemNames) = $event)),
                      items: _ctx.itemNames,
                      label: "Selecionar Itens",
                      multiple: "",
                      chips: "",
                      "menu-props": { closeOnContentClick: false },
                      rules: [_ctx.selectionRule()],
                      ref: "selectInput"
                    }, {
                      selection: _withCtx((data) => [
                        (_openBlock(), _createBlock(_component_v_chip, {
                          key: data.item,
                          close: "",
                          "onClick:close": ($event: any) => (_ctx.removeItem(data.item))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(data.item), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick:close"]))
                      ]),
                      _: 1
                    }, 8, ["modelValue", "items", "rules"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}