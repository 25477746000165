import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, toHandlers as _toHandlers, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-space-between" }
const _hoisted_2 = {
  href: "/clusters/store",
  class: "text-decoration-none"
}
const _hoisted_3 = { class: "text-sm-body-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[7] || (_cache[7] = _createElementVNode("h1", { class: "" }, "Clusters", -1)),
      _createElementVNode("a", _hoisted_2, [
        _createVNode(_component_v_btn, {
          class: "text-white elevation-0",
          color: "movida-orange"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, null, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode(" mdi-plus")
              ])),
              _: 1
            }),
            _cache[6] || (_cache[6] = _createTextVNode(" Novo cluster "))
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_v_data_table_server, {
      "items-per-page": _ctx.itemsPerPage,
      "onUpdate:itemsPerPage": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.itemsPerPage) = $event)),
      headers: _ctx.headers,
      items: _ctx.items,
      loading: _ctx.loading,
      "item-value": "name",
      class: "elevation-1",
      "hide-default-footer": ""
    }, {
      loading: _withCtx(() => [
        _createVNode(_component_v_sheet, { class: "d-flex align-center justify-center pt-3 pb-3" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_chip, null, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode(" Carregando... ")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      bottom: _withCtx(() => _cache[9] || (_cache[9] = [])),
      [`item.status`]: _withCtx(({ item }) => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", {
            class: _normalizeClass([{ 'bg-red': !item.props.title.is_enabled, 'bg-green': !!item.props.title.is_enabled }, "w-3 h-3 rounded-circle d-inline-block mr-2"])
          }, null, 2),
          _createTextVNode(" " + _toDisplayString(!!item.props.title.is_enabled ? "Ativo" : "Inativo"), 1)
        ])
      ]),
      [`item.options`]: _withCtx(({ item }) => [
        (item.props.title.is_enabled)
          ? (_openBlock(), _createBlock(_component_v_menu, {
              key: 0,
              bottom: "",
              left: ""
            }, {
              activator: _withCtx(({ on, props }) => [
                _createVNode(_component_v_btn, _mergeProps({
                  dark: "",
                  icon: ""
                }, props, _toHandlers(on), { style: {"box-shadow":"none"} }), {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                        _createTextVNode("mdi-dots-vertical")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1040)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_v_list, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item, {
                      href: '/clusters/update/' + item.props.title.id
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_list_item_title, null, {
                          default: _withCtx(() => _cache[11] || (_cache[11] = [
                            _createTextVNode("Editar Cluster")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1032, ["href"]),
                    _createVNode(_component_v_list_item, {
                      href: '/showcase/update/' + item.props.title.id
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_list_item_title, null, {
                          default: _withCtx(() => _cache[12] || (_cache[12] = [
                            _createTextVNode("Editar Vitrine")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1032, ["href"]),
                    _createVNode(_component_v_list_item, {
                      onClick: ($event: any) => (_ctx.destroy(item.props.title.id))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_list_item_title, null, {
                          default: _withCtx(() => _cache[13] || (_cache[13] = [
                            _createTextVNode("Deletar Cluster")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024))
          : _createCommentVNode("", true)
      ]),
      _: 2
    }, 1032, ["items-per-page", "headers", "items", "loading"]),
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.confirmDialog,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.confirmDialog) = $event)),
          "max-width": "500px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "headline" }, {
                  default: _withCtx(() => _cache[14] || (_cache[14] = [
                    _createTextVNode("Confirmação")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => _cache[15] || (_cache[15] = [
                    _createTextVNode("Você tem certeza que deseja deletar este item?")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.confirmDialog = false))
                    }, {
                      default: _withCtx(() => _cache[16] || (_cache[16] = [
                        _createTextVNode("Cancelar")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _ctx.confirmDelete
                    }, {
                      default: _withCtx(() => _cache[17] || (_cache[17] = [
                        _createTextVNode("Deletar")
                      ])),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_v_snackbar, {
          modelValue: _ctx.snackbar,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.snackbar) = $event)),
          timeout: 3000,
          color: _ctx.snackbarColor
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.snackbarText) + " ", 1),
            _createVNode(_component_v_btn, {
              color: "white",
              text: "",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.snackbar = false))
            }, {
              default: _withCtx(() => _cache[18] || (_cache[18] = [
                _createTextVNode("Close")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue", "color"])
      ]),
      _: 1
    })
  ], 64))
}