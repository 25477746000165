import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "app" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "child" }
const _hoisted_4 = { class: "child" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h1", null, "Perfil", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.todo, (t, i) => {
            return (_openBlock(), _createElementBlock("li", { key: i }, _toDisplayString(i) + " - " + _toDisplayString(t.title), 1))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (u, i) => {
            return (_openBlock(), _createElementBlock("li", { key: i }, _toDisplayString(u.email), 1))
          }), 128))
        ])
      ])
    ])
  ]))
}