import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/brand-light.svg'


const _hoisted_1 = { class: "d-flex justify-center align-center min-h-100vh" }
const _hoisted_2 = { class: "mt-2 d-flex flex-column w-100" }
const _hoisted_3 = {
  key: 0,
  class: "message-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_flex = _resolveComponent("v-flex")!

  return (_openBlock(), _createElementBlock("div", null, [
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "logo" }, [
      _createElementVNode("img", {
        alt: "Logo Movida",
        src: _imports_0
      })
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_flex, { class: "justify-center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, {
                elevation: "5",
                class: "pa-md-16 pa-sm-4 px-md-16 px-sm-4 rounded-lg"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, { "no-gutters": "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_container, null, {
                        default: _withCtx(() => [
                          _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "text-left text-neutral-900" }, "Token MFA", -1)),
                          _cache[3] || (_cache[3] = _createElementVNode("p", { class: "text-neutral-600" }, " Insira o código de verificação que você recebeu:", -1)),
                          _createElementVNode("div", _hoisted_2, [
                            _createVNode(_component_v_text_field, {
                              modelValue: _ctx.verificationCode,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.verificationCode) = $event)),
                              label: "Insira o Token MFA",
                              dense: "",
                              outlined: "",
                              "hide-details": "",
                              style: {"margin-bottom":"50px"},
                              maxlength: "6"
                            }, null, 8, ["modelValue"]),
                            _createVNode(_component_v_btn, {
                              color: "movida-orange",
                              size: "large",
                              block: "",
                              onClick: _ctx.verifyCode
                            }, {
                              default: _withCtx(() => _cache[1] || (_cache[1] = [
                                _createTextVNode(" Acessar ")
                              ])),
                              _: 1
                            }, 8, ["onClick"])
                          ]),
                          (_ctx.showMessage)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                _createElementVNode("div", {
                                  class: _normalizeClass(['message-balloon', _ctx.messageColor])
                                }, _toDisplayString(_ctx.msgToken), 3)
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ]))
}