import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "root" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("h2", null, "Recuperar conta", -1)),
    _createElementVNode("p", null, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        placeholder: "Email",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.username) = $event))
      }, null, 512), [
        [_vModelText, _ctx.form.username]
      ])
    ]),
    _createElementVNode("p", null, [
      (_ctx.form.username.length > 4)
        ? _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 0,
            type: "password",
            placeholder: "Password",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.password) = $event))
          }, null, 512)), [
            [_vModelText, _ctx.form.password]
          ])
        : _createCommentVNode("", true)
    ]),
    (_ctx.form.username.length > 4 && _ctx.form.password.length > 4)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.doLogin && _ctx.doLogin(...args)))
        }, "Acessar"))
      : _createCommentVNode("", true)
  ]))
}