import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex align-end justify-space-between" }
const _hoisted_2 = { class: "col-sm-12 col-lg-6" }
const _hoisted_3 = { class: "col-sm-12 col-lg-6 d-flex profile-buttons" }
const _hoisted_4 = { class: "text-decoration-none" }
const _hoisted_5 = { class: "text-decoration-none" }
const _hoisted_6 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_container, { class: "d-flex flex-column" }, {
      default: _withCtx(() => [
        _createElementVNode("header", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _cache[8] || (_cache[8] = _createElementVNode("h3", null, "Perfil", -1)),
            _createElementVNode("h2", null, _toDisplayString(_ctx.profileDescription.name), 1)
          ]),
          _createElementVNode("nav", _hoisted_3, [
            _createElementVNode("a", _hoisted_4, [
              _createVNode(_component_v_btn, {
                class: "text-white",
                color: "grey",
                href: "/profiles"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createTextVNode(" mdi-plus ")
                    ])),
                    _: 1
                  }),
                  _cache[10] || (_cache[10] = _createTextVNode(" Voltar "))
                ]),
                _: 1
              })
            ]),
            _createElementVNode("a", _hoisted_5, [
              _createVNode(_component_v_btn, {
                class: "text-white",
                color: "movida-orange",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.update(true)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                      _createTextVNode(" mdi-plus ")
                    ])),
                    _: 1
                  }),
                  _cache[12] || (_cache[12] = _createTextVNode(" Salvar Alterações "))
                ]),
                _: 1
              })
            ])
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        _createElementVNode("fieldset", null, [
          _createVNode(_component_v_container, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "2",
                    md: "3"
                  }, {
                    default: _withCtx(() => _cache[13] || (_cache[13] = [
                      _createElementVNode("h4", null, "Módulo para editar", -1)
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "3",
                    md: "5"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_select, {
                        modelValue: _ctx.module,
                        "onUpdate:modelValue": [
                          _cache[1] || (_cache[1] = ($event: any) => ((_ctx.module) = $event)),
                          _ctx.onChangeModule
                        ],
                        items: _ctx.modules,
                        "item-title": "name",
                        "item-value": "id",
                        "persistent-hint": "",
                        "return-object": "",
                        "single-line": "",
                        disabled: _ctx.isLoading
                      }, null, 8, ["modelValue", "items", "onUpdate:modelValue", "disabled"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }),
    (_ctx.module.id !== 0 && _ctx.functionalities.length > 0)
      ? (_openBlock(), _createBlock(_component_v_container, { key: 0 }, {
          default: _withCtx(() => [
            _createElementVNode("fieldset", null, [
              _createVNode(_component_v_container, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { cols: "6" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, { cols: "12" }, {
                                default: _withCtx(() => _cache[14] || (_cache[14] = [
                                  _createElementVNode("h4", { class: "mb-2" }, "Funcionalidades", -1)
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_v_col, { cols: "6" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_text_field, {
                                    class: "pt-2",
                                    label: "Filtrar",
                                    "prepend-inner-icon": "mdi-magnify",
                                    clearable: "",
                                    "single-line": "",
                                    density: "compact",
                                    variant: "solo",
                                    "hide-details": "",
                                    modelValue: _ctx.functionalityForSearch,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.functionalityForSearch) = $event)),
                                    onInput: _ctx.getFunctionalitiesByNeedle
                                  }, null, 8, ["modelValue", "onInput"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, { cols: "4" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_switch, {
                                    modelValue: _ctx.showEnabledFunctionalities,
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showEnabledFunctionalities) = $event)),
                                    label: "Ativos",
                                    color: "movida-orange",
                                    "hide-details": "",
                                    onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.getAllFunctionalities(true)))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          (_ctx.functionalities.length > 0)
                            ? (_openBlock(), _createBlock(_component_v_list, {
                                key: 0,
                                destiny: "compact"
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.functionalities, (functionality) => {
                                    return (_openBlock(), _createBlock(_component_v_list_item, {
                                      key: functionality.id,
                                      value: functionality,
                                      "active-color": "primary",
                                      onClick: ($event: any) => (_ctx.getPermissionsByFunctionality(functionality))
                                    }, {
                                      prepend: _withCtx(() => [
                                        _createVNode(_component_v_icon, {
                                          icon: functionality.icon
                                        }, null, 8, ["icon"]),
                                        _createVNode(_component_v_list_item_title, {
                                          textContent: _toDisplayString(functionality.name)
                                        }, null, 8, ["textContent"])
                                      ]),
                                      _: 2
                                    }, 1032, ["value", "onClick"]))
                                  }), 128))
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_divider, {
                        class: "ms-3",
                        inset: "",
                        vertical: "",
                        style: {"color":"#3b4651","border-width":"1.5px"}
                      }),
                      (_ctx.functionality.name !== '')
                        ? (_openBlock(), _createBlock(_component_v_col, {
                            key: 0,
                            cols: "5"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_list, {
                                destiny: "compact",
                                class: "permission-list"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_card, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_checkbox, {
                                        modelValue: _ctx.functionality.selected,
                                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.functionality.selected) = $event)),
                                        readonly: "",
                                        label: _ctx.functionality.name
                                      }, null, 8, ["modelValue", "label"])
                                    ]),
                                    _: 1
                                  }),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.functionality.permissions, (permission, i) => {
                                    return (_openBlock(), _createBlock(_component_v_list_item, {
                                      key: i,
                                      value: permission,
                                      "active-color": "primary"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_switch, {
                                          class: "switch-route",
                                          color: "movida-orange",
                                          label: permission.title,
                                          modelValue: permission.isEnabled,
                                          "onUpdate:modelValue": ($event: any) => ((permission.isEnabled) = $event),
                                          onChange: ($event: any) => (_ctx.updatePermission(permission))
                                        }, null, 8, ["label", "modelValue", "onUpdate:modelValue", "onChange"])
                                      ]),
                                      _: 2
                                    }, 1032, ["value"]))
                                  }), 128))
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_v_container, { class: "d-flex align-center justify-center" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_snackbar, {
          modelValue: _ctx.snackbar,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.snackbar) = $event)),
          timeout: _ctx.timeout
        }, {
          actions: _withCtx(() => [
            _createVNode(_component_v_btn, {
              variant: "text",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.snackbar = false))
            }, {
              default: _withCtx(() => _cache[16] || (_cache[16] = [
                _createTextVNode(" Fechar ")
              ])),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createVNode(_component_v_icon, { class: "ml-1" }, {
              default: _withCtx(() => _cache[15] || (_cache[15] = [
                _createTextVNode(" mdi-information-outline ")
              ])),
              _: 1
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.text) + " ", 1)
          ]),
          _: 1
        }, 8, ["modelValue", "timeout"])
      ]),
      _: 1
    }),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_v_container, { key: 1 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_v_progress_circular, {
                indeterminate: "",
                color: "movida-orange"
              })
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}