const AUTH = {
  SIGNIN: {
    href: "/auth/loginPage.vue",
    route: "/",
    url: "/",
    name: "",
  },
  SELECTSYSTEM: {
    href: "/auth/systemsPage.vue",
    route: "/select-system",
    url: "/",
    name: "",
  },
  SIGNUP: {
    href: "/auth/signUp.vue",
    route: "/sign-up",
    url: "/sign-up",
    name: "",
  },
  FORGOTPASSWORD: {
    href: "/auth/forgotPassword.vue",
    route: "/forgot-password",
    url: "/forgot-password",
    name: "",
  },
};

const DASHBOARD = {
  HOME: {
    href: "./dashboard/index.vue",
    route: "/home",
    url: "/home",
    name: "dashboard.index",
  },
  USERS: {
    href: "./users/index.vue",
    route: "/users",
    url: "/users",
    name: "users.index",
  },
  MODULES_LIST: {
    href: "./modules/index.vue",
    route: "/modules",
    url: "/modules",
    name: "modules.index",
  },
  MODULES_CREATE: {
    href: "./modules/create.vue",
    route: "/modules/store",
    url: "/modules/store",
    name: "modules.store",
  },
  MODULES_UPDATE: {
    href: "./modules/update.vue",
    route: "/modules/update/:module",
    url: "/modules/update/:module",
    name: "modules.update",
  },
  PROFILE_LIST: {
    href: "./profiles/index.vue",
    route: "/profiles",
    url: "/profiles",
    name: "profiles.index",
  },
  PROFILE_CREATE: {
    href: "./profiles/create.vue",
    route: "/profiles/store",
    url: "/profiles/store",
    name: "profiles.index",
  },
  PROFILE_UPDATE: {
    href: "./profiles/update.vue",
    route: "/profiles/:profile",
    url: "/profiles/:profile",
    name: "profiles.index",
  },
  PROFILE_SHOW_ALL_PERMISSIONS: {
    href: "./profiles/showAllPermissions.vue",
    route: "/profiles/:profile/all-permissions",
    url: "/profiles/:profile/all-permissions",
    name: "profiles.show-all-permissions",
  },
  FUNCTIONALITY_LIST: {
    href: "./functionalities/index.vue",
    route: "/functionalities",
    url: "/functionalities",
    name: "functionalities.index",
  },
  FUNCTIONALITY_CREATE: {
    href: "./functionalities/create.vue",
    route: "/functionalities/store",
    url: "/functionalities/store",
    name: "functionalities.store",
  },
  FUNCTIONALITY_EDIT: {
    href: "./functionalities/update.vue",
    route: "/functionalities/update/:functionality",
    url: "/functionalities/update/:functionality",
    name: "functionalities.update",
  },
  ROUTES_LIST: {
    href: "./routes/index.vue",
    route: "/routes",
    url: "/routes",
    name: "routes.index",
  },
  ROUTES_CREATE: {
    href: "./routes/create.vue",
    route: "/routes/store",
    url: "/routes/store",
    name: "routes.store",
  },
  ROUTES_UPDATE: {
    href: "./routes/update.vue",
    route: "/routes/update/:module",
    url: "/routes/update/:module",
    name: "routes.update",
  },
  ACCESS_KEY_LIST: {
    href: "./access-key/index.vue",
    route: "/access-key",
    url: "/access-key",
    name: "access-key.index",
  },
  ACCESS_KEY_CREATE: {
    href: "./access-key/create.vue",
    route: "/access-key/store",
    url: "/access-key/store",
    name: "access-key.store",
  },
  ACCESS_KEY_UPDATE: {
    href: "./access-key/update.vue",
    route: "/access-key/update/:access-key",
    url: "/access-key/update/:access-key",
    name: "access-key.update",
  },
  MONITORING_LIST: {
    href: "./backoffice/monitoring/index.vue",
    route: "/monitoring",
    url: "/monitoring",
    name: "backoffice.monitoring.index",
  },
  CATALOG_LIST: {
    href: "./catalog/index.vue",
    route: "/catalog",
    url: "/catalog",
    name: "catalog.index",
  },
  CATALOG_VIEW: {
    href: "./catalog/view.vue",
    route: "/catalog/view/:catalog",
    url: "/catalog/view/:catalog",
    name: "catalog.view",
  },
  SCHEDULE_LIST: {
    href: "./schedules/index.vue",
    route: "/schedules",
    url: "/schedules",
    name: "schedules.index",
  },
  SCHEDULE_CREATE: {
    href: "./schedules/index.vue",
    route: "/schedules/store",
    url: "/schedules/store",
    name: "schedules.store",
  },
  SHOWCASE_LIST: {
    href: "./vitrine/index.vue",
    route: "/vitrine",
    url: "/vitrine",
    name: "vitrine.index",
  },
  CLUSTER_LIST: {
    href: "./cluster/index.vue",
    route: "/clusters",
    url: "/clusters",
    name: "clusters.index",
  },
  DEADLINE_LIST: {
    href: "./deadlines/index.vue",
    route: "/deadlines",
    url: "/deadlines",
    name: "deadlines.index",
  },
  LEADTIME_LIST: {
    href: "./leadtime/index.vue",
    route: "/leadtime",
    url: "/leadtime",
    name: "leadtime.index",
  },
};

const API = {
  API: {
    href: "/api/v1",
    route: "/api/v1",
    url: "/api/v1",
    name: "",
  },
};

export default { ...AUTH, ...DASHBOARD, ...API };
