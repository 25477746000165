import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/brand.svg'


const _hoisted_1 = {
  id: "container-top-navigation",
  class: "mx-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!

  return (_openBlock(), _createBlock(_component_v_navigation_drawer, {
    value: _ctx.show,
    app: "",
    permanent: "",
    color: "#F6F8F9",
    width: "240"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_router_link, { to: "/home" }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("img", {
              class: "my-4 ml-3",
              alt: "Logo Movida",
              src: _imports_0
            }, null, -1)
          ])),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          md: "12",
          class: "nav-header"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_select, {
              modelValue: _ctx.location,
              "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event: any) => ((_ctx.location) = $event)),
                _ctx.changeModuleSelected
              ],
              items: _ctx.modules,
              "item-title": "name",
              "item-value": "id",
              variant: "plain",
              label: "Gestão de produtos",
              class: "navbar-select"
            }, null, 8, ["modelValue", "items", "onUpdate:modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_divider, { class: "my-1" }),
        _createVNode(_component_v_row, { class: "mt-2" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_list, {
              density: "default",
              class: "navbar-left"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
                  return (_openBlock(), _createBlock(_component_v_list_item, {
                    key: i,
                    value: item,
                    href: item.href,
                    class: _normalizeClass(["rounded-lg navbar-list", { 'is-active': _ctx.getIsActive(item.href) }])
                  }, {
                    prepend: _withCtx(() => [
                      _createVNode(_component_v_icon, {
                        icon: item.icon
                      }, null, 8, ["icon"])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_title, {
                        textContent: _toDisplayString(item.text)
                      }, null, 8, ["textContent"])
                    ]),
                    _: 2
                  }, 1032, ["value", "href", "class"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["value"]))
}