<template>
  <v-navigation-drawer :value="show" app permanent color="#F6F8F9" width="240">
    <div id="container-top-navigation" class="mx-3">
      <router-link to="/home">
        <img class="my-4 ml-3" alt="Logo Movida" src="@/assets/brand.svg" />
      </router-link>
      <v-col cols="12" md="12" class="nav-header">
        <v-select v-model="location" :items="modules" item-title="name" item-value="id" variant="plain" label="Gestão de produtos" @update:modelValue="changeModuleSelected" class="navbar-select"></v-select>
      </v-col>
      <v-divider class="my-1"></v-divider>
      <v-row class="mt-2">
        <v-list density="default" class="navbar-left">
          <v-list-item v-for="(item, i) in items" :key="i" :value="item" :href="item.href" class="rounded-lg navbar-list" :class="{ 'is-active': getIsActive(item.href) }">
            <template v-slot:prepend>
              <v-icon :icon="item.icon"></v-icon>
            </template>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-row>
    </div>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { ModuleWithFunctionalities } from "@/models/auth/ModuleWithFunctionalities";
import { Menu } from "@/models/menu/Menu";
import { Options, Vue } from "vue-class-component";
import Funcionalities from "@/utils/Funcionalities";
import Modules from "@/utils/Modules";

@Options({
  components: {},
})
export default class NavbarLeft extends Vue {
  private modulesClass = new Modules();
  private functionalitiesClass = new Funcionalities();
  public show = true;
  public location: string | null = "";
  public moduleSelectedId = 0;
  public modules: Array<ModuleWithFunctionalities> = [];
  public isActive = false;

  mounted(): void {
    this.getModuleSelected();
    this.getModules();
    this.getMenu();
  }

  getIsActive(url: string): boolean {
    const active = this.$router.currentRoute.value.path;
    return active === url;
  }

  get items(): Array<Menu> {
    return this.$store.getters["menu"];
  }

  async getModuleSelected(): Promise<void> {
    this.location = this.modulesClass.getModuleNameSelected();
  }

  async changeModuleSelected(value: string): Promise<void> {
    this.moduleSelectedId = parseInt(value);
    this.modulesClass.saveModuleKey(this.moduleSelectedId);
    await this.modulesClass.getNameModuleService(this.moduleSelectedId);
    this.getMenu();
    this.$router.push("/home");
  }

  getModules(): void {
    this.modules = this.modulesClass.modules;
  }

  getMenu(): void {
    this.functionalitiesClass.getFuncionalitiesMenu();
  }

  // compare(a: any, b: any): any {
  //   if (a.menu_order < b.menu_order) {
  //     return -1;
  //   }
  //   if (a.menu_order > b.menu_order) {
  //     return 1;
  //   }
  //   return 0;
  // }
}
</script>
<style lang="scss" scoped>
.v-list-item:hover {
  opacity: 1 !important;
}
.v-list-item:hover .v-list-item-title,
.v-list-item:hover .v-icon {
  color: var(--movida-orange) !important;
}

.is-active {
  color: var(--movida-orange) !important;
  background-color: white !important;
}
.nav-header {
  padding: 0 12px;
}

.v-list-item--variant-text {
  background-color: var(--neutral-050) !important;
}

.v-row {
  margin: auto;
}

.v-list {
  width: 100% !important;
}

.v-list-item-title {
  font-weight: 500 !important;
}
</style>
