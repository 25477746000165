<template>
  <div class="d-flex justify-space-between mb-6">
    <h2 class="title">Catálogo</h2>
    <v-btn color="movida-orange" title="Botão para limpeza de todos os grupos." @click="clearCacheGroups"> Limpar cache </v-btn>
  </div>
  <v-row class="mb-3 flex justify-between">
    <v-col md="3" sm="4">
      <v-text-field v-model="needleSearch" clearable density="compact" hide-details label="Buscar..." prepend-inner-icon="mdi-magnify" single-line variant="solo" @input="searchByNeedle" @click:clear="clearFilter"></v-text-field>
    </v-col>
    <v-col md="7" sm="6">
      <div class="d-flex justify-start">
        <div class="d-flex align-center gap-4 mr-6">
          <v-menu transition="slide-y-transition">
            <template v-slot:activator="{ props }">
              <v-btn color="white" v-bind="props">
                <v-icon> mdi-filter-variant</v-icon>
                Filtrar por:
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, i) in headersOrdenar" :key="i" :value="i">
                <v-list-item-title @click="changeSortSelected(item.key)">{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="d-flex gap-4 mr-6">
          <v-menu transition="slide-y-transition">
            <template v-slot:activator="{ props }">
              <v-btn color="white" v-bind="props">
                Ordenar por:
                <v-icon class="ml-1" dark left> mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, i) in headersOrdenar" :key="i" :value="i">
                <v-list-item-title @click="changeSortSelected(item.key)">{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </v-col>
  </v-row>
  <v-data-table-server v-model:items-per-page="itemsPerPage" :headers="headers" :items="items" :loading="isLoadingTable" class="elevation-0 rounded-lg" hide-default-footer item-value="name" to="asd">
    <template v-slot:bottom></template>
    <template v-slot:[`item.name`]="{ item }">
      <v-img style="width: 82px; height: 46px" v-bind:src="'https://static.seminovosmovida.com.br/storage_dev/veiculo/1570719/729236_aff4d8519acd6cdd3decd279ef143cee.webp'" @click="goToCatalog(item)"></v-img>
    </template>
    <template v-slot:[`item.description`]="{ item }">
      <tr class="clickable" @click="goToCatalog(item)">
        <v-row>
          <p>{{ item.props.title.Grupo }}</p>
        </v-row>
        <v-row>
          <p>{{ item.props.title.GrupoID }} • {{ item.props.title.SimuladorAMPF_CodigoFIPE }}</p>
        </v-row>
      </tr>
    </template>
    <template v-slot:[`item.options`]="{ item }">
      <v-menu bottom left>
        <template v-slot:activator="{ props }">
          <v-btn dark icon style="box-shadow: none" v-bind="props">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="goToCatalog(item)">
            <v-list-item-title>Editar</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title style="color: red">Desabilitar encomendas</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table-server>
  <v-row class="mt-2 d-flex align-center" justify="center">
    <div class="text-center">
      <v-pagination v-model="currentPage" :length="numberOfPages" :total-visible="7" color="movida-orange" @next="nextPage" @prev="prevPage" @update:modelValue="loadPage"></v-pagination>
      <div> {{ pageFrom }} a {{ pageTo }} de {{ totalOfRows }} resultados</div>
    </div>
  </v-row>
  <v-snackbar v-model="snackbar" :timeout="timeout">
    <v-icon class="ml-1"> mdi-information-outline</v-icon>
    {{ text }}
    <template v-slot:actions>
      <v-btn variant="text" @click="closeSnack"> Fechar</v-btn>
    </template>
  </v-snackbar>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import * as services from "@/services";
import { Catalog } from "@/models/catalog/Catalog";
import { getFromStorage } from "@/utils/localStorage";
import messages from "@/constants/messages";

@Options({
  components: {},
})
export default class CatalogIndexView extends Vue {
  public items: Array<Catalog> = [];
  public module_channels: any;
  public currentPage = 0;
  public numberOfPages = 0;
  public itemsPerPage = 0;
  public totalOfRows = 0;
  public pageFrom = 0;
  public pageTo = 1;
  public headers = [
    { title: "Veículo", align: "start", key: "name", sortable: false },
    { title: "Descrição", align: "start", key: "description", sortable: false },
    { title: "Ações", align: "end", key: "options", sortable: false },
  ];
  public headersOrdenar = [
    { title: "Recentes", align: "start", key: "name", sortable: false },
    { title: "Grupo", align: "start", key: "description", sortable: false },
  ];
  public sortSelected = "name";
  public isLoadingTable = true;
  public needleSearch = null;
  public snackbar = false;
  public text = "";
  public messages = {};
  public timeout = 10000;

  mounted(): void {
    this.module_channels = getFromStorage("MODULE_CHANNEL");
    this.willMountDataPage();
  }

  async willMountDataPage(): Promise<void> {
    const { currentPage } = this;
    const response = await services.CatalogService.getAllCatalogs(this.module_channels, currentPage);

    this.unMountRequest(response);

    this.changeLoading();
  }

  nextPage(): void {
    this.loadPage(this.currentPage);
  }

  prevPage(): void {
    this.loadPage(this.currentPage);
  }

  loadPage(value: number): void {
    this.changeLoading();
    this.getCatalogList(value, this.needleSearch, this.sortSelected);
    this.scrollToTop();
  }

  changeLoading(): void {
    this.isLoadingTable = !this.isLoadingTable;
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  async getCatalogList(page: number, needle: string | null = null, sortColumn: string | null = null) {
    try {
      if (this.isNullableSort(sortColumn)) {
        this.resetSortSelected();
        sortColumn = this.sortSelected;
      }
      const response = await services.CatalogService.getAllCatalogs(this.module_channels, page, needle, sortColumn);
      this.unMountRequest(response);
    } catch (e) {
      console.log("ERRO", e);
    }
    this.changeLoading();
  }

  async clearCacheGroups(): Promise<void> {
    const response = await services.CatalogService.clearCatalogSync();

    if (response.has_success) {
      this.snackbar = true;
      this.text = response.message;
    } else {
      this.snackbar = true;
      this.text = messages.fail;
    }
  }

  closeSnack(): void {
    this.snackbar = false;
  }

  goToCatalog(catalog: any) {
    this.$router.push({
      path: "/catalog/view/" + catalog.props.title.GrupoID,
      query: {
        descritivo: catalog.props.title.Grupo,
        grupoId: catalog.props.title.GrupoID,
        fipe: catalog.props.title.SimuladorAMPF_CodigoFIPE,
        modelo: catalog.props.title.Modelos,
      },
    });
  }

  searchByNeedle() {
    if (this.needleSearch != null && Array.from(this.needleSearch).length > 3) {
      this.changeLoading();
      this.items = [];
      this.getCatalogList(1, this.needleSearch);
    }
  }

  public clearFilter() {
    this.changeLoading();
    this.items = [];
    this.currentPage = 1;
    this.getCatalogList(1);
  }

  changeSortSelected(itemSelected: string) {
    this.sortSelected = itemSelected;
    this.changeLoading();
    this.getCatalogList(this.currentPage, null, itemSelected);
  }

  private isNullableSort(sort: string | null) {
    return sort === null;
  }

  private resetSortSelected() {
    this.loadSortSelected();
  }

  private loadSortSelected() {
    this.sortSelected = this.headers[0].key;
  }

  private unMountRequest(response: any) {
    if (response.results) {
      this.currentPage = response.results.meta.pagination.current_page;
      this.items = response.results.data as Array<Catalog>;
      this.numberOfPages = response.results.meta.pagination.total_pages;
      this.itemsPerPage = response.results.meta.pagination.per_page;
      this.totalOfRows = response.results.meta.pagination.total;
      this.pageFrom = response.results.meta.pagination.current_page;
      this.pageTo = response.results.meta.pagination.per_page;
    }
  }
}
</script>
<style lang="css"></style>
