<template>
  <main>
    <div class="flex-col-gap-2" v-if="!loading">
      <v-row class="flex justify-center gap-1">
        <v-col class="total" v-for="(value, key) in status" :key="key">
          <section>
            <header>
              {{ key }}
            </header>
            <main>
              {{ value.total }}
            </main>
            <footer>
              <v-chip v-for="(modalityValue, modalityKey) in value.modality" :key="modalityKey" color="movida-orange" class="ma-1" size="x-small" density="default"> {{ modalityKey }}: {{ modalityValue }} </v-chip>
            </footer>
          </section>
        </v-col>
      </v-row>
      <div class="flex-end">
        <v-btn @click="dialogStatus = true" color="movida-orange" variant="outlined" icon="mdi-pencil-outline"> </v-btn>
      </div>
    </div>
    <div class="flex-center" v-else>
      <v-progress-circular color="movida-orange" indeterminate></v-progress-circular>
    </div>
    <v-dialog v-model="dialogStatus" width="auto">
      <v-card>
        <v-card-title class="bg-white"> Selecionar Status </v-card-title>
        <v-card-subtitle class="bg-white"> Escolha os status que deseja visualizar nos cards </v-card-subtitle>
        <v-row class="mt-2 flex-center">
          <v-btn class="text-movida-orange" elevation="0" @click="_statusToShow = allStatus" density="compact">Marcar todos</v-btn>
          <v-divider vertical></v-divider>
          <v-btn class="text-movida-orange" elevation="0" @click="_statusToShow = []" density="compact">Desmarcar todos</v-btn>
        </v-row>
        <v-col>
          <div v-for="(status_key, index) in allStatus" :key="index">
            <v-checkbox v-model="_statusToShow" :label="status_key" hide-details :value="status_key" density="compact" color="black"> </v-checkbox>
            <v-switch v-model="_statusToShowModality" hide-details :label="status_key" :value="status_key" class="ml-6" v-if="_statusToShow.includes(status_key)" density="compact" color="movida-orange">
              <template v-slot:label> Exibir modalidades </template>
            </v-switch>
            <v-divider></v-divider>
          </div>
        </v-col>
        <v-card-actions class="space-between">
          <v-btn class="bg-movida-orange my-2" elevation="0" @click="dialogStatus = false">Fechar</v-btn>
          <v-btn class="bg-movida-orange my-2 float-right" elevation="0" @click="confirm()">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </main>
</template>
<script lang="ts">
import { Vue } from "vue-class-component";

class Props {
  status?: any;
  loading?: boolean;
  allStatus?: any;
  statusToShow?: any;
  statusToShowModality?: any;
}
export default class CountStatusComponent extends Vue.with(Props) {
  public dialogStatus = false;
  private _statusToShow = this.statusToShow;
  private _statusToShowModality = this.statusToShowModality;

  confirm() {
    this.$emit("confirmStatus", this._statusToShow, this._statusToShowModality);
    this.dialogStatus = false;
  }
}
</script>
<style>
.total {
  background-color: white;
  border: 1px solid #ff5000;
  border-radius: 15px;
  height: 6rem;
  padding: 4px !important;
}

.total section {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-wrap: nowrap;
}
.total header {
  font-size: 0.8rem;
  font-weight: 100;
  color: gray;
}
.total main {
  font-size: 1.5rem;
  font-weight: bold;
}
.total footer {
  min-height: 40px;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.gap-1 {
  gap: 1rem;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.flex-col-gap-2 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
