import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onMousedown", "onMouseup"]
const _hoisted_2 = {
  width: "3%",
  class: "crown"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["onMousedown", "onMouseup"]
const _hoisted_5 = ["onMouseup"]
const _hoisted_6 = ["onMouseup"]
const _hoisted_7 = { width: "4%" }
const _hoisted_8 = ["onMouseup"]
const _hoisted_9 = { width: "12%" }
const _hoisted_10 = { width: "7%" }
const _hoisted_11 = { width: "7%" }
const _hoisted_12 = { width: "7%" }
const _hoisted_13 = { width: "9%" }
const _hoisted_14 = { width: "11%" }
const _hoisted_15 = { width: "11%" }
const _hoisted_16 = { width: "10%" }
const _hoisted_17 = { width: "7%" }
const _hoisted_18 = { width: "9%" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_data_table_server, {
      "items-per-page": _ctx.privateOrdersPerPage,
      "onUpdate:itemsPerPage": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.privateOrdersPerPage) = $event)),
      headers: _ctx.headers,
      items: _ctx.orders,
      loading: _ctx.isLoadingTable,
      class: "elevation-1 orders-list fix-thead",
      "hide-default-footer": "",
      "item-value": "name",
      to: "asd",
      "items-per-page-text": 'Itens por página'
    }, {
      body: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orders, (item) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: item._id,
            class: "hover-table",
            onMousedown: ($event: any) => (_ctx.mouseDownOrder(item)),
            onMouseup: ($event: any) => (_ctx.mouseUpOrder(item))
          }, [
            _createElementVNode("td", _hoisted_2, [
              (item.tipo_veiculo == 'Super Vip')
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, " 👑"))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("td", {
              width: "4%",
              class: "clickable",
              onMousedown: ($event: any) => (_ctx.goToSchedule(item.pedido_id, item.data_agendamento, $event)),
              onMouseup: ($event: any) => (_ctx.mouseUpOrder(item))
            }, _toDisplayString(item.pedido_id), 41, _hoisted_4),
            _createElementVNode("td", {
              width: "5%",
              class: "clickable",
              onMousedown: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToReservation($event))),
              onMouseup: ($event: any) => (_ctx.mouseUpOrder(item))
            }, _toDisplayString(item.reserva_id), 41, _hoisted_5),
            _createElementVNode("td", {
              width: "5%",
              class: "clickable",
              onMousedown: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToContract($event))),
              onMouseup: ($event: any) => (_ctx.mouseUpOrder(item))
            }, _toDisplayString(item.contrato_nro), 41, _hoisted_6),
            _createElementVNode("td", _hoisted_7, _toDisplayString(item.final_placa), 1),
            _createElementVNode("td", {
              width: "6%",
              class: "clickable",
              onMousedown: _cache[2] || (_cache[2] = ($event: any) => (_ctx.goToPlate($event))),
              onMouseup: ($event: any) => (_ctx.mouseUpOrder(item))
            }, _toDisplayString(item.placa), 41, _hoisted_8),
            _createElementVNode("td", _hoisted_9, _toDisplayString(item.versao), 1),
            _createElementVNode("td", _hoisted_10, _toDisplayString(item.cor), 1),
            _createElementVNode("td", _hoisted_11, _toDisplayString(item.nome_cliente), 1),
            _createElementVNode("td", _hoisted_12, _toDisplayString(item.cpf_cliente), 1),
            _createElementVNode("td", _hoisted_13, _toDisplayString(item.celular), 1),
            _createElementVNode("td", _hoisted_14, _toDisplayString(item.loja_retirada), 1),
            _createElementVNode("td", _hoisted_15, _toDisplayString(item.loja_atual), 1),
            _createElementVNode("td", _hoisted_16, _toDisplayString(_ctx.formatDate(item.data_agendamento)), 1),
            _createElementVNode("td", _hoisted_17, _toDisplayString(item.responsavel_agendamento), 1),
            _createElementVNode("td", _hoisted_18, [
              _createVNode(_component_v_chip, {
                variant: "outlined",
                size: "x-small"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.status_monitoramento), 1)
                ]),
                _: 2
              }, 1024)
            ])
          ], 40, _hoisted_1))
        }), 128))
      ]),
      [`bottom`]: _withCtx(() => [
        _createVNode(_component_v_row, {
          class: "d-flex align-center p-2",
          justify: "end"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createElementVNode("div", { class: "flex-end" }, [
                  _createElementVNode("div", { class: "item-per-page" }, " Pedidos por página: ")
                ], -1)
              ])),
              _: 1
            }),
            _createVNode(_component_v_col, { cols: "1" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_select, {
                  modelValue: _ctx.privateOrdersPerPage,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.privateOrdersPerPage) = $event)),
                  items: _ctx.itemsPerPageOptions,
                  variant: "outlined",
                  density: "compact",
                  "hide-details": ""
                }, null, 8, ["modelValue", "items"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 2
    }, 1032, ["items-per-page", "headers", "items", "loading"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.dialogInformation,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.dialogInformation) = $event)),
      width: "600"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, { class: "mx-3 mt-1" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, null, {
                  default: _withCtx(() => [
                    _cache[14] || (_cache[14] = _createElementVNode("h3", { class: "h3-card" }, "Inserir informações do atendimento", -1)),
                    _createVNode(_component_v_row, { class: "mb-1" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          md: "4",
                          sm: "12"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              label: "Pedido ID",
                              modelValue: _ctx.active_item.pedido_id,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.active_item.pedido_id) = $event)),
                              readonly: "",
                              variant: "outlined",
                              "hide-details": ""
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          md: "8",
                          sm: "12"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              label: "Nome Cliente",
                              modelValue: _ctx.active_item.nome_cliente,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.active_item.nome_cliente) = $event)),
                              readonly: "",
                              variant: "outlined",
                              "hide-details": ""
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_textarea, {
                      label: "Informação do atendimento",
                      modelValue: _ctx.active_information,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.active_information) = $event)),
                      "error-messages": _ctx.error_info,
                      variant: "outlined",
                      disabled: _ctx.disabledInformation
                    }, null, 8, ["modelValue", "error-messages", "disabled"]),
                    _createVNode(_component_v_btn, {
                      class: "bg-movida-orange mb-2",
                      elevation: "0",
                      onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.dialogInformation = false))
                    }, {
                      default: _withCtx(() => _cache[12] || (_cache[12] = [
                        _createTextVNode("Fechar")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      class: "bg-movida-orange float-right mb-2",
                      elevation: "0",
                      onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.saveInformation())),
                      loading: _ctx.loadingInformation
                    }, {
                      default: _withCtx(() => _cache[13] || (_cache[13] = [
                        _createTextVNode("Salvar")
                      ])),
                      _: 1
                    }, 8, ["loading"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (_ctx.active_item_informations.length > 0)
              ? (_openBlock(), _createBlock(_component_v_divider, { key: 0 }))
              : _createCommentVNode("", true),
            (_ctx.active_item_informations.length > 0)
              ? (_openBlock(), _createBlock(_component_v_row, {
                  key: 1,
                  class: "mx-3 mt-2"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, null, {
                      default: _withCtx(() => [
                        _cache[15] || (_cache[15] = _createElementVNode("h3", { class: "h3-card" }, "Histórico de Informações", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.active_item_informations, (information) => {
                          return (_openBlock(), _createBlock(_component_v_card, {
                            variant: "outlined",
                            key: information._id,
                            class: "my-1",
                            width: "100%",
                            density: "compact"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_card_text, { class: "information" }, {
                                default: _withCtx(() => [
                                  _createElementVNode("header", null, _toDisplayString(_ctx.formatDate(information.data)) + " - " + _toDisplayString(information.usuario), 1),
                                  _createElementVNode("main", null, _toDisplayString(information.informacao), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_v_card_actions, { class: "v-card-dialog-actions" })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}