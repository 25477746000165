import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex-col-gap-2"
}
const _hoisted_2 = { class: "flex-end" }
const _hoisted_3 = {
  key: 1,
  class: "flex-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("main", null, [
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_v_row, { class: "flex justify-center gap-1" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.status, (value, key) => {
                return (_openBlock(), _createBlock(_component_v_col, {
                  class: "total",
                  key: key
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("section", null, [
                      _createElementVNode("header", null, _toDisplayString(key), 1),
                      _createElementVNode("main", null, _toDisplayString(value.total), 1),
                      _createElementVNode("footer", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value.modality, (modalityValue, modalityKey) => {
                          return (_openBlock(), _createBlock(_component_v_chip, {
                            key: modalityKey,
                            color: "movida-orange",
                            class: "ma-1",
                            size: "x-small",
                            density: "default"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(modalityKey) + ": " + _toDisplayString(modalityValue), 1)
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ])
                    ])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_v_btn, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialogStatus = true)),
              color: "movida-orange",
              variant: "outlined",
              icon: "mdi-pencil-outline"
            })
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_v_progress_circular, {
            color: "movida-orange",
            indeterminate: ""
          })
        ])),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.dialogStatus,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.dialogStatus) = $event)),
      width: "auto"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, { class: "bg-white" }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode(" Selecionar Status ")
              ])),
              _: 1
            }),
            _createVNode(_component_v_card_subtitle, { class: "bg-white" }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode(" Escolha os status que deseja visualizar nos cards ")
              ])),
              _: 1
            }),
            _createVNode(_component_v_row, { class: "mt-2 flex-center" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  class: "text-movida-orange",
                  elevation: "0",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx._statusToShow = _ctx.allStatus)),
                  density: "compact"
                }, {
                  default: _withCtx(() => _cache[10] || (_cache[10] = [
                    _createTextVNode("Marcar todos")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_divider, { vertical: "" }),
                _createVNode(_component_v_btn, {
                  class: "text-movida-orange",
                  elevation: "0",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx._statusToShow = [])),
                  density: "compact"
                }, {
                  default: _withCtx(() => _cache[11] || (_cache[11] = [
                    _createTextVNode("Desmarcar todos")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allStatus, (status_key, index) => {
                  return (_openBlock(), _createElementBlock("div", { key: index }, [
                    _createVNode(_component_v_checkbox, {
                      modelValue: _ctx._statusToShow,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx._statusToShow) = $event)),
                      label: status_key,
                      "hide-details": "",
                      value: status_key,
                      density: "compact",
                      color: "black"
                    }, null, 8, ["modelValue", "label", "value"]),
                    (_ctx._statusToShow.includes(status_key))
                      ? (_openBlock(), _createBlock(_component_v_switch, {
                          key: 0,
                          modelValue: _ctx._statusToShowModality,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx._statusToShowModality) = $event)),
                          "hide-details": "",
                          label: status_key,
                          value: status_key,
                          class: "ml-6",
                          density: "compact",
                          color: "movida-orange"
                        }, {
                          label: _withCtx(() => _cache[12] || (_cache[12] = [
                            _createTextVNode(" Exibir modalidades ")
                          ])),
                          _: 2
                        }, 1032, ["modelValue", "label", "value"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_divider)
                  ]))
                }), 128))
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, { class: "space-between" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  class: "bg-movida-orange my-2",
                  elevation: "0",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.dialogStatus = false))
                }, {
                  default: _withCtx(() => _cache[13] || (_cache[13] = [
                    _createTextVNode("Fechar")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_btn, {
                  class: "bg-movida-orange my-2 float-right",
                  elevation: "0",
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.confirm()))
                }, {
                  default: _withCtx(() => _cache[14] || (_cache[14] = [
                    _createTextVNode("Confirmar")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}